<script>
export default {
  name: "OrderUXPreferencesSettings",
  data() {
    return {
      showModal: false,

      autosave_inputs: true
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    applyChanges() {
      localStorage.setItem('autosave_inputs', this.autosave_inputs)

      window.location.reload()
    }
  },
  mounted() {
    this.autosave_inputs = localStorage.getItem('autosave_inputs') ? JSON.parse(localStorage.getItem('autosave_inputs')) === true : true
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="User Preferences" centered hide-footer>
    <b-list-group flush>
      <BListGroupItem class="border rounded mb-2">
        <div class="d-flex justify-content-between align-items-center gap-3 mb-1">
          <label class="form-check-label fs-5" for="AutoSaveInputs">AutoSave Inputs</label>
          <div class="form-check form-switch form-switch-success">
            <input v-model="autosave_inputs" class="form-check-input" type="checkbox" role="switch" id="AutoSaveInputs"
                   :checked="autosave_inputs">
          </div>
        </div>
        <p class="mb-0 fs-13 text-muted">
          Auto saves the changes when you leave the input
        </p>
      </BListGroupItem>
    </b-list-group>

    <div class="mt-2">
      <b-button @click="applyChanges()" variant="success" class="w-100">
        Apply Changes
      </b-button>
    </div>
  </b-modal>
</template>

<style scoped>

</style>