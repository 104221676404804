<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AddExpense",
  emits: ['updated'],
  props: {
    counterparty_options: {
      type: Array,
      required: true,
      default: () => []
    },
    category_options: {
      type: Array,
      required: true,
      default: () => []
    },
    container_types: {
      type: Array,
      required: true,
      default: () => []
    },
    order_counterparties: {
      type: Array,
      required: true,
      default: () => []
    },
    order_number: {
      type: Number,
      required: true,
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      showModal: false,
      category_id: null,
      counterparty_id: null,
      container_types_costs: []
    }
  },
  computed: {
    counterparties() {
      return this.counterparty_options.map((option) => {
        return {
          value: option.id,
          label: option.name
        }
      })
    },
    categories() {
      return this.category_options.map((option) => {
        return {
          value: option.id,
          label: option.name
        }
      })
    },
    formIsValid() {
      return this.counterparty_id && this.category_id && !this.expense_exists && this.container_types_costs.every(i => typeof i.preliminary_cost === 'number' && !isNaN(i.preliminary_cost) && i.preliminary_cost >= 0)
    },
    expense_exists() {
      return this.order_counterparties.filter(i => i.counterparty_id === this.counterparty_id && i.category.id === this.category_id).length > 0
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async submitForm() {
      try {
        await axios.post(`/container_order/expanse/counterparty_add/`, {
          order_number: this.order_number,
          counterparty_id: this.counterparty_id,
          category_id: this.category_id,
          preliminary_costs: this.container_types_costs.map((container_type) => {
            return {
              container_type_id: container_type.id,
              preliminary_cost: container_type.preliminary_cost
            }
          })
        })
        this.showModal = false
        this.$emit('updated')
        await Swal.fire({
          title: 'Success',
          text: 'New Counterparty has been added successfully',
          icon: 'success'
        })
      } catch {
        await Swal.fire({
          title: 'Oops...',
          text: 'Couldn\'t add a new counterparty',
          icon: 'error'
        })
      }
    }
  },
  watch: {
    container_types: {
      handler(newVal) {
        this.container_types_costs = newVal ? newVal.map(i => {
          return {
            ...i,
            preliminary_cost: ''
          }
        }) : [];
      },
      immediate: true,
    },
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Add New Expense" centered hide-header hide-footer>

    <div class="d-flex flex-column gap-2 align-items-center w-100 mb-4">
              <span class="badge badge-soft-success">
                New
              </span>
      <h5 class="mb-0">
        Counterparty
      </h5>
    </div>

    <!-- Danger Alert -->
    <BAlert :show="expense_exists" variant="danger" class="border-0 " role="alert">
      <strong> Attention! </strong> - Counterparty with this category already exists!
    </BAlert>


    <div class="d-flex flex-column gap-3">
      <div>
        <label class="form-label">Counterparty</label>
        <Multiselect v-model="counterparty_id" :options="counterparties" :searchable="true"/>
      </div>
      <div>
        <label class="form-label">Category</label>
        <Multiselect v-model="category_id" :options="categories" :searchable="true"/>
      </div>
      <div class="row gy-2">
        <template v-for="container_type in container_types_costs" :key="`container_type_${container_type.id}`">
          <div class="col-12">
            <div class="d-flex align-items-center border rounded-3 ps-3">
              <span v-b-tooltip.hover title="Container type" class="border-end pe-3 border-success">
                {{ container_type.container_type }}
              </span>
              <input v-model="container_type.preliminary_cost" type="number"
                     class="form-control form-control-icon border-0"
                     :id="`container_type_${container_type.id}`" placeholder="Preliminary cost">
            </div>
          </div>
        </template>
      </div>
      <div>
        <b-button @click="submitForm()"
                  :disabled="!formIsValid"
                  variant="success" class="w-100">
          Confirm & Submit
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>