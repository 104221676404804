<template>
  <div class="text-start">
    <DeleteConfirmation
        :id="this.modal_id"
        header="Delete Container"
        :confirmationPlaceholder="data.container ? data.container.name : data.id"
        @onDelete="onDeleteConfirmed"
    >
    </DeleteConfirmation>
  </div>
</template>

<script>
import DeleteConfirmation from "@/components/forms/DeleteConfirmation.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ['update'],
  name: "document_delete",
  props: {
    container: {
      type: Object,
      default: () => {
      }
    },
    modal_id: {
      type: String,
      required: true,
    },
    expense_id: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async onDeleteConfirmed() {
      try {
        await axios.delete(`/container_order/delete_row/${this.expense_id}/`)
        try {
          document.querySelector(`#${this.modal_id} .btn-close`).click()
        } catch {
          console.log('Failed to close modal')
        }
        this.$emit('update')
        await Swal.fire({
          icon: 'success',
          title: 'Container deleted successfully',
          showConfirmButton: false,
          timer: 2000,
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
  computed: {
    data() {
      return this.container || {}
    }
  },
  components: {
    DeleteConfirmation
  }
}
</script>

<style scoped>

</style>