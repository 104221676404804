<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ConnectCodes",
  emits: ['updated'],
  data() {
    return {
      showModal: false,

      counterparty_id: null,
      original_counterparty_id: null,
      counterparty_name: null,
      category_id: null,
      category_name: null,
      order_number: null,

      category_options: [],
      order_containers: [],
      order_containers_text: '',
      order_codes_text: '',

      selected_year: new Date().getFullYear(),
      errors: {}
    }
  },
  computed: {
    counterparty_category_id() {
      return {
        counterparty_id: this.counterparty_id,
        category_id: this.category_id,
      }
    }
  },
  methods: {
    openModal(counterparty, categories, order_number, order_containers) {
      this.errors = {}
      if (counterparty && categories && order_number) {
        try {
          this.original_counterparty_id = counterparty.counterparty_id
          this.counterparty_name = counterparty.name
          this.category_options = categories
          this.order_containers = order_containers

          this.order_number = order_number
          this.showModal = true

          if (this.category_options.length === 1) {
            this.selectCategory(this.category_options[0])
          } else {
            this.category_id = null
          }
        } catch {
          alert("Something went wrong here")
        }
      } else {
        this.showModal = false
        alert("You cannot connect code to this counterparty!")
      }
    },

    selectCategory(category) {
      this.counterparty_id = category.counterparty_id
      this.category_id = category.category.id
      this.category_name = category.category.name
    },
    selectOtherCategory() {
      this.counterparty_id = null
      this.category_id = null
      this.category_name = null
    },

    validateForm() {
      this.errors = {}
      if (this.order_containers_text.split('\n').length !== this.order_codes_text.split('\n').length) {
        this.errors.quantity = 'Containers & Codes quantity does not match!'
      }

      let containers = this.order_containers_text.split('\n');
      let containerSet = new Set();

      containers.forEach((container) => {
        if (containerSet.has(container)) {
          this.errors[container] = 'Duplicate container: ' + container
        } else {
          containerSet.add(container);
        }
      });

    },
    async submitForm() {

      this.validateForm()
      if (Object.keys(this.errors).length > 0) return

      try {
        await axios.post(`/code/connect_order_code/${this.order_number}/`, {
          loading_type: 'container',
          category_id: this.category_id,
          counterparty_id: this.original_counterparty_id,
          codes: this.order_codes_text.split('\n'),
          containers_or_wagons: this.order_containers_text.split('\n'),
          year: this.selected_year
        })
        this.$emit('updated')
        await Swal.fire({
          title: 'Success',
          text: 'You have successfully connected codes',
          icon: 'success'
        })
        this.showModal = false
        this.order_containers_text = ''
        this.order_codes_text = ''

        this.counterparty_id = null
        this.original_counterparty_id = null
        this.counterparty_name = null
        this.category_id = null
        this.category_name = null
        this.order_number = null

      } catch (e) {
        await Swal.fire({
          title: 'Oops..',
          text: e.response.data.message || "Something went wrong here",
          icon: 'error'
        })
      }
    }
  },
  watch: {
    counterparty_category_id: {
      handler(newValue) {

        this.order_containers_text = ''
        this.order_codes_text = ''

        if (newValue) {
          let actual_costs = this.order_containers.filter(i => {
            return i.container && i.expenses.filter(e => e.counterparty_id === this.counterparty_id).length > 0
          }).map(i => {
            return {
              ...i,
              expenses: i.expenses.filter(e => e.counterparty_id === this.counterparty_id)
            }
          })
          actual_costs.forEach((actual_cost, actual_cost_index) => {
            this.order_containers_text += actual_cost.container.name
            let expense = actual_cost.expenses[0]
            if (expense.code) {
              this.order_codes_text += expense.code.number
            }
            if (actual_costs.length - 1 === actual_cost_index ? '' : '\n') {
              this.order_containers_text += '\n'
              this.order_codes_text += '\n'
            }
          })

          if (this.order_codes_text.split('\n').filter(i => i !== '').length === 0) {
            this.order_codes_text = ''
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" :title="counterparty_name" hide-footer centered>

    <nav aria-label="breadcrumb">
      <nav aria-label="breadcrumb">
        <b-breadcrumb>
          <b-breadcrumb-item active>
            Order {{ order_number }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>{{ counterparty_name }}</b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ category_id ? category_name : 'Select Category' }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </nav>
    </nav>

    <div>
      <div class="row g-3" v-if="!category_id">
        <template v-for="category in category_options" :key="`category_${category.counterparty_id}`">
          <div class="col-6 col-lg-4">
            <b-button @click="selectCategory(category)" variant="soft-success" class="w-100">
              {{ category.category.name }}
            </b-button>
          </div>
        </template>
      </div>
      <div class="row g-3" v-else>

        <div class="col-12 col-xl-6">
          <textarea v-model="order_containers_text" class="form-control" :rows="order_containers.length || 10"
                    placeholder="Containers..."></textarea>
        </div>
        <div class="col-12 col-xl-6">
          <textarea v-model="order_codes_text" class="form-control" :rows="order_containers.length || 10"
                    placeholder="Codes..."></textarea>
        </div>

        <div v-if="Object.keys(errors).length > 0" class="col-12">
          <ul class="error_ul">
            <li class="error_li" v-for="error in Object.entries(errors)" :key="`error_${error}`">
              {{ error[1] }}
            </li>
          </ul>
        </div>

        <div class="col-12 col-xl-6">
          <select v-model="selected_year" class="form-select">
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>

        <div class="col-12 col-xl-6">
          <b-button @click="submitForm()" class="w-100" variant="success">
            Connect Codes
          </b-button>
        </div>

        <div class="col-12">
          <b-button @click="selectOtherCategory()" class="w-100" variant="light">
            <i class="mdi mdi-chevron-left"></i> Select other category
          </b-button>
        </div>
      </div>
    </div>

  </b-modal>
</template>

<style scoped>
.error_ul {
  list-style-type: disc; /* Ensures bullets are shown */
}

.error_li::marker {
  color: red; /* Change this to your desired color */
}

</style>