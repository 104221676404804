<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Container",
  emits: ['show_container_explanation_modal', 'updated'],
  props: {
    expense_id: {
      type: Number,
      required: true,
    },
    container_type_id: {
      type: Number,
      required: true,
    },
    container: {
      type: [Object],
      required: true
    }
  },
  data() {
    return {
      url: 'https://order.interrail.uz/container_order/expanse/update/15589/',
      wrong_container: false,

      container_name: '',
      has_unsaved_changes: false,

      preferences: {
        autosave_inputs: true
      }
    }
  },
  methods: {
    async onInputBlur() {
      if (this.preferences.autosave_inputs && this.has_unsaved_changes) {
        await this.updateContainer(true)
      }
    },
    validateContainerName(name) {
      if (name.trim().length === 0) {
        this.wrong_container = false
        return
      }
      const validPattern = /^[A-Z]{3}U\d{7}$/;
      this.wrong_container = !validPattern.test(name);
      if (this.wrong_container) {
        console.error('Invalid container name');
      }
    },
    showContainerExplanationModal() {
      this.$emit('show_container_explanation_modal')
    },

    async updateContainer(is_auto_saving = false) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
      });

      if (this.wrong_container) {
        await Toast.fire({
          title: 'Validation Error',
          text: 'Please, make sure to enter valid container number',
          icon: 'warning'
        })
        return
      }

      if (!this.has_unsaved_changes) {
        await Toast.fire({
          title: 'Nothing changed yet',
          icon: 'info'
        })
        return
      }

      try {
        await axios.put(`/container_order/expanse/update/${this.expense_id}/`, {
          container_type_id: this.container_type_id,
          container_name: this.container_name.trim()
        })
        this.has_unsaved_changes = false
        this.$emit('updated')
        await Toast.fire({
          title: is_auto_saving ? 'AutoSave Successful' : 'Successfully updated',
          text: this.container_name.trim(),
          icon: 'success'
        })
      } catch {
        await Toast.fire({
          title: 'Error',
          icon: 'error'
        })
      }
    }
  },
  watch: {
    container: {
      handler(newVal) {
        this.container_name = newVal ? newVal.name : '';
      },
      immediate: true,
    },
    container_name: {
      handler(newValue) {
        this.validateContainerName(newValue)
        this.has_unsaved_changes = this.container_name !== this.container.name
      },
      immediate: true
    }
  },
  mounted() {
    this.preferences.autosave_inputs = localStorage.getItem('autosave_inputs') ? JSON.parse(localStorage.getItem('autosave_inputs')) === true : true
  }
}
</script>

<template>
  <div class="y position-relative">

    <input v-model="container_name" placeholder="Container name"
           class="form-control form-control-sm"
           :class="{
    'border border-warning border-2': has_unsaved_changes,
    'border-danger border-2': wrong_container,
    'border-0': !wrong_container && !has_unsaved_changes,
         }"

           v-on:keyup.enter="updateContainer(false)"
           @blur="onInputBlur()"
    >

    <span v-if="wrong_container" @click="showContainerExplanationModal()"
          class="position-absolute top-50 translate-middle" style="right: -2px">
      <i class="ri-error-warning-line rounded-circle align-middle text-danger fw-bold" style="padding: 2px"></i>
    </span>

    <span v-if="has_unsaved_changes"
          v-b-tooltip.hover title="Unsaved changes"
          class="position-absolute top-0 translate-middle" style="right: -7px">
      <i class="mdi mdi-circle text-warning" style="font-size: 10px"></i>
    </span>

  </div>

</template>

<style scoped>

</style>