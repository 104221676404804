<script>
import {defineComponent} from 'vue'
import ContainerDeleteModal from "../../modals/ContainerDelete.vue";

export default defineComponent({
  name: "DeleteContainer",
  emits: ['update'],
  props: {
    index: {
      type: Number,
      required: true,
    },
    container: {
      type: Object,
      required: true,
    },
  },
  components: {
    ContainerDeleteModal
  },
  computed: {
    expense_id() {
      return this.container.id
    }
  }
})
</script>

<template>
  <ContainerDeleteModal
      :modal_id="`DeleteContainerModal${expense_id}_${index}`"
      :container="container"
      :expense_id="expense_id"
      @update="this.$emit('update')"
  />
  <div class="btn-container me-3 bg-danger">
    <Transition name="slide-up">
      <span class="animation-item mx-2" v-if="!container.is_hovered">{{ index + 1 }}</span>
      <i v-else-if="container.is_hovered"
         data-bs-toggle="modal"
         :data-bs-target="`#DeleteContainerModal${expense_id}_${index}`"
         class="mdi mdi-trash-can fs-20 animation-item align-top text-danger c_icon_hoverable"></i>
    </Transition>
  </div>
</template>


<style>
.btn-container {
  display: inline-block;
  position: relative;
  height: 1em;
}

.animation-item {
  position: absolute;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>