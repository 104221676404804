<script>
import {defineComponent} from 'vue'
import Counterparty from "./components/Counterparty.vue"
import store from "@/state/store";

export default defineComponent({
  name: "ActualCostTab",
  emits: ['update'],
  props: {
    order_counterparties: {
      type: Array,
      required: true,
      default: () => []
    },
    order_container_types: {
      type: Array,
      required: true,
      default: () => []
    },
    counterparty_list: {
      type: Array,
      required: true,
      default: () => []
    },
    category_list: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  components: {
    Counterparty,
  },
  data() {
    return {
      blacklisted_users_ids: []
    }
  },
  computed: {
    counterparties() {
      return this.order_counterparties
    },
    container_types() {
      return this.order_container_types
    },
    agreed_rate_sum() {
      let sum = 0
      this.container_types.forEach(container_type => {
        container_type.expanses.forEach(expense => {
          sum += parseFloat(expense.agreed_rate)
        })
      })
      return sum.toFixed(1)
    },
    additional_cost_sum() {
      let sum = 0
      this.container_types.forEach(container_type => {
        container_type.expanses.forEach(expense => {
          sum += parseFloat(expense.additional_cost)
        })
      })
      return sum.toFixed(2)
    },
    sum_total_expenses() {
      return this.counterparties.reduce((total, counterparty) =>
          total + parseFloat(this.sumCounterpartyExpenses(counterparty)), 0);
    },
    sum_profit() {
      return (this.agreed_rate_sum - this.sum_total_expenses).toFixed(1)
    },
    user() {
      return store.state.user
    }
  },
  methods: {
    updateCounterparty(counterparty) {
      console.log(counterparty)
    },
    sumCounterpartyExpenses(counterparty) {
      return (this.container_types.flatMap(container_type => container_type.expanses)
          .flatMap(expense => expense.actual_costs)
          .filter(actual_cost => actual_cost.counterparty_id === counterparty.id)
          .reduce((total, actual_cost) => total + parseFloat(actual_cost.actual_cost), 0))
          .toFixed(1)
    },
    calculateContainerTotal(container) {
      if (!container.actual_costs) return 0
      return container.actual_costs
          .map(c => c.actual_cost ? c.actual_cost : 0)
          .reduce((a, actual_cost) => {
            return a + parseFloat(actual_cost);
          }, 0)
          .toFixed(1)
    },
    calculateContainerProfit(container) {
      if (container) {
        return ((parseFloat(container.agreed_rate) + parseFloat(container.additional_cost)) -
            this.calculateContainerTotal(container)
        ).toFixed(1)
      }
    },
  }
})
</script>

<template>
  <div class="tab-pane" id="preliminary_cost_tab" role="tabpanel">
    <div class="table-responsive table-card">
      <table class="table table-striped mb-0">
        <thead>
        <tr class="bg-light align-middle">
          <th class="text-center">#</th>
          <th class="text-center">Container</th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">Agreed rate</th>
          <th class="text-center">Additional Cost</th>
          <th class="text-center py-0 m-0" v-for="counterparty in counterparties"
              :key="`counterparty + ${counterparty.id}`"
              @click="updateCounterparty(counterparty)">
            <Counterparty
                @update="this.$emit('update')"
                :counterparty="counterparty"
                :counterparty_list="counterparty_list"
                :category_list="category_list"
            />
          </th>
          <th class="text-center">Total</th>
          <th v-if="!blacklisted_users_ids.includes(user.id)" class="text-center">Profit</th>
        </tr>
        <tr class="bg-light align-middle fs-5">
          <th class="text-center">
          </th>
          <th class="text-center">
          </th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">$ {{ agreed_rate_sum }}</th>
          <th class="text-center">
            ${{ additional_cost_sum }}
          </th>
          <th class="text-center m-0" v-for="party in counterparties"
              :key="party.id">
            ${{ sumCounterpartyExpenses(party) }}
          </th>
          <th class="text-center">${{ sum_total_expenses }}</th>
          <th class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">${{ sum_profit }}</th>
        </tr>
        </thead>
        <tbody v-for="ctr_type in container_types" :key="ctr_type">
        <tr class="border-success">
          <th class="my-1 bg-transparent text-success"
              :colspan="blacklisted_users_ids.includes(user.id) ? 5 : 6 + counterparties.length">
            <div class="d-flex justify-content-start">
              {{ ctr_type.container_type }}
            </div>
          </th>
        </tr>
        <tr class="align-middle text-center"
            v-for="(container, i) in ctr_type.expanses" :key="container"
            @mouseover="container.is_hovered = true"
            @mouseleave="container.is_hovered = false"
        >
          <th>
            {{ i + 1 }}
          </th>
          <td class="text-center" style="max-width: 125px">
            {{ container.container ? container.container.name : '--' }}
          </td>
          <td class="text-center" v-if="!blacklisted_users_ids.includes(user.id)">
            <input type="number" class="form-control form-control-sm w-75 mx-auto"
                   :value="container.agreed_rate"
                   readonly
            >
          </td>
          <td class="text-center">
            <input type="number" class="form-control form-control-sm w-75 mx-auto"
                   :value="container.additional_cost"
                   readonly
            >
          </td>
          <td class="text-center"
              v-for="counterparty in ctr_type.container_preliminary_costs"
              :key="counterparty.id" style="max-width: 65px">
            <input type="number" class="form-control form-control-sm w-75 mx-auto"
                   :value="counterparty.preliminary_cost"
                   readonly
            >
          </td>
          <td>
            ${{ calculateContainerTotal(container) }}
          </td>
          <td v-if="!blacklisted_users_ids.includes(user.id)">
            ${{ calculateContainerProfit(container) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>

</style>