<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ContainerInput",
  emits: ['update'],
  props: {
    expense_id: {
      type: Number,
      required: true,
    },
    container_type_id: {
      type: Number,
      required: true,
    },
    container: {
      type: Object,
      required: true,
      default: () => {}
    },
    in_terminal: {
      type: Boolean,
      required: true,
      default: () => false
    },
    acts_list: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      inputClass: '',
    }
  },
  computed: {
    ctr() {
      return this.container
    }
  },
  methods: {
    searchedContainerColor() {
      if (this.$route.query.container) {
        let query = this.$route.query.container.trim().toLowerCase()
        let container = this.ctr.name.trim().toLowerCase()
        if (container.includes(query)) {
          return 'border-success border-3'
        } else {
          return ''
        }
      }
    },
    async saveNewContainer(event) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      if (event.target.value.trim().length !== 11) {
        await Toast.fire({
          icon: 'warning',
          title: 'Container name must be 11 characters',
        })
        return
      }

      try {
        await axios.put(`/container_order/expanse/update/${this.expense_id}/`, {
          container_name: event.target.value.trim(),
          container_type_id: this.container_type_id
        })
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'Container name updated',
        })
      } catch (err) {
        await Toast.fire({
          icon: 'error',
          title: Object.values(err.response.data.extra.fields).join('\n'),
        })
      }
    },
    async saveExistingContainer() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put(`/container_order/expanse/update/${this.expense_id}/`, {
          container_name: this.ctr.name,
          container_type_id: this.container_type_id
        })
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'Container name updated',
        })
      } catch (err) {
        try {
          await Toast.fire({
            icon: 'error',
            title: err.response.data.message,
          })
        } catch {
          await Toast.fire({
            icon: 'error',
            title: 'Something went wrong',
          })
        }
      }
    }
  }
})
</script>

<template>
  <template v-if="ctr">
    <input v-if="acts_list.length === 0" maxlength="11"
           class="form-control form-control-sm"
           :class="[inputClass, searchedContainerColor(), in_terminal ? 'bg-soft-success': '']"
           type="text" placeholder="Container"
           v-model="ctr.name"
           v-on:keyup.enter="saveExistingContainer(ctr.id)"
           style="min-width: 95px"
    >
    <VTooltip v-if="acts_list.length > 0">
      <router-link :to="{name: 'terminal', query: { order: this.$route.params.id, container: ctr.name }}">
        <input maxlength="11"
               class="form-control cursor-pointer form-control-sm"
               :class="[inputClass, searchedContainerColor(), in_terminal ? 'bg-soft-success': '']"
               type="text" placeholder="Container"
               :value="ctr.name"
               style="min-width: 95px" disabled
        >
      </router-link>
      <template v-slot:popper>
        <div class="p-2 rounded shadow-sm">
          <p>
            This field is disabled because <br> container is connected to the following acts:
          </p>
          <div class="d-flex justify-content-between align-items-center mb-1">
            <span>Acts</span>
            <span class="badge bg-success">{{ acts_list.length }}</span>
          </div>
          <div class="table-responsive">
            <table class="table table-striped mb-0">
              <tbody>
              <tr v-for="(act, index) in acts_list" :key="`table_${act}`">
                <th class="text-light" scope="row">{{ index + 1 }}</th>
                <td class="text-light">{{ act }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </VTooltip>
  </template>
  <input v-else-if="ctr === null"
         maxlength="11"
         class="form-control form-control-sm" :class="inputClass"
         type="text" placeholder="Container"
         v-on:keyup.enter="saveNewContainer"
         style="min-width: 95px"
  >

  <div v-if="ctr !== null && ctr.code !== null" class="y position-relative">
    <span class="order-connected-code position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"> {{
        ctr.code
      }}
    </span>
  </div>
</template>

<style scoped>

</style>