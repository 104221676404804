<script>
import {defineComponent} from 'vue'
import Swal from "sweetalert2";
import axios from "axios";
import store from "@/state/store";

export default defineComponent({
  name: "Counterparty",
  emits: ['update'],
  props: {
    counterparty: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    counterparty_list: {
      type: Array,
      required: true,
      default: () => []
    },
    category_list: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  methods: {
    async updateCounterparty(item) {
      const {value: formValues} = await Swal.fire({
        title: 'Update Counterparty',
        html:
            '<select class="form-select m-auto w-75 mt-3" id="categoryUpdate">' +
            `${this.category_list.map(c => {
              return item.category.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>' +
            '<select class="form-select m-auto w-75 mt-3" id="counterpartyUpdate">' +
            `${this.counterparty_list.map(c => {
              return item.counterparty.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>',
        focusConfirm: false,
        confirmButtonText: 'Save',
        confirmButtonColor: '#0AB39C',
        preConfirm: () => {
          return [
            document.getElementById('counterpartyUpdate').value,
            document.getElementById('categoryUpdate').value
          ]
        }
      })

      if (formValues) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        try {
          await axios.put(`/order/counterparty/update/${item.id}/`, {
            "category_id": formValues[1],
            "counterparty_id": formValues[0]
          })
          this.$emit('update')
          await Toast.fire({
            icon: 'success',
            title: 'Successfully updated'
          })
        } catch {
          await Toast.fire({
            icon: 'error',
            title: 'Something went wrong'
          })
        }
      }
    },
  },
  computed: {
    has_access_to_visit_counterparty_profile() {
      return store.state.user.pages.includes('counterparty_profile_main')
    }
  }
})
</script>

<template>
  <div class="dropdown ms-1 topbar-head-dropdown header-item">
    <div type="button" data-bs-toggle="dropdown">
      <span class="badge bg-success">{{ counterparty.category.name }}</span>
      <span class="d-block">{{ counterparty.counterparty.name }}</span>
    </div>
    <div class="dropdown-menu dropdown-menu-end">
      <a class="dropdown-item cursor-pointer d-flex justify-content-between align-middle py-2"
         @click="updateCounterparty(counterparty)">
        Edit
        <i class="mdi mdi-pencil"></i>
      </a>
      <router-link
          v-if="counterparty.counterparty && counterparty.counterparty.company_slug && has_access_to_visit_counterparty_profile"
          :to="{
            name: 'counterparty_profile_main',
            params: {slug: counterparty.counterparty.company_slug},
            query: {order: this.$route.params.id}
          }"
          class="dropdown-item cursor-pointer d-flex justify-content-between align-bottom py-2">
        Visit Profile
        <font-awesome-icon class="text-secondary" :icon="['fas', 'up-right-from-square']"/>
      </router-link>
    </div>
  </div>
</template>

<style scoped>

</style>