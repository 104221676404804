<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ActualCost",
  emits: ['updated'],
  props: {
    actual_cost: {
      type: String,
      required: true,
      default: '0'
    },
    actual_cost_id: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
    container_type_id: {
      type: Number,
      required: true,
    },
    counterparty_id: {
      type: Number,
      required: true,
    },
    code: {
      type: [null, Object],
      required: false,
      default: () => null
    },
    act: {
      type: [null, Object],
      required: false,
      default: () => null
    },
    forwarder_name: {
      type: String,
      required: true,
      default: () => ''
    },
    forwarder_id: {
      type: Number,
      required: true,
    },
    value_option: {
      type: String,
      required: false,
      default: () => 'actual_costs'
    },
  },
  data() {
    return {
      actual_actual_cost: '',
      has_unsaved_changes: false,

      preferences: {
        autosave_inputs: true
      },

      code_in_focus: false,
      code_options: [],
      expense_code: {
        number: ''
      },
      is_performing_action: false,
      code_disconnect_confirmed: false
    }
  },
  methods: {
    async onInputBlur() {
      if (this.preferences.autosave_inputs && this.has_unsaved_changes) {
        await this.updateActualCost(true)
      }
    },

    async updateActualCost(is_auto_saving = false) {

      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
      });

      if (!this.has_unsaved_changes) {
        await Toast.fire({
          title: 'Nothing changed yet',
          icon: 'info'
        })
        return
      }

      if (parseFloat(this.actual_actual_cost || '0') < 0) {
        if (is_auto_saving) {
          await Toast.fire({
            title: 'AutoSave Warning',
            text: 'Expense must be greater than or equal to 0!',
            icon: 'warning'
          })
        } else {
          await Toast.fire({
            title: 'Expense must be greater than or equal to 0!',
            icon: 'warning'
          })
        }
        return
      }

      try {
        this.is_performing_action = true
        await axios.put(`/container_order/expanse/actual_cost/update/${this.actual_cost_id}/`, {
          actual_cost: this.actual_actual_cost
        })

        this.has_unsaved_changes = false
        this.$emit('updated')

        this.is_performing_action = false

        if (this.index === 0) {
          await Swal.fire({
            icon: "info",
            title: "$ " + parseFloat(this.actual_actual_cost).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            text: "Would you like to apply this price to all rows of this counterparty",
            showCancelButton: true,
            confirmButtonText: "Yes, apply all!",
            cancelButtonText: 'No',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.updateAllActualCost(this.actual_actual_cost)
            }
          });
        } else {
          await Toast.fire({
            title: is_auto_saving ? 'AutoSave Successful' : 'Successfully updated',
            text: parseFloat(this.actual_actual_cost).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            icon: 'success'
          })
        }
      } catch {
        await Toast.fire({
          title: 'Error',
          icon: 'error'
        })
      }
    },

    async updateAllActualCost(actual_cost = 0) {
      try {
        this.is_performing_action = true
        await axios.put(`/container_order/expanse/actual_cost_to_all/`, {
          "container_type_id": this.container_type_id,
          "counterparty_id": this.counterparty_id,
          "actual_cost": actual_cost
        })
        this.$emit("updated")
        this.is_performing_action = false
        await Swal.fire({
          title: "Success",
          text: "All expense values have been updated to " + parseFloat(actual_cost || '0').toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }),
          icon: 'success'
        })
      } catch {
        this.is_performing_action = false
        await Swal.fire({
          title: "Error",
          text: "The expense value has been successfully updated for the first row, but we encountered an error while updating the other rows. Please try again or contact support if the issue persists",
          icon: 'error'
        })
      }
    },

    async searchCodeNumbers(status) {
      this.code_options = []
      this.code_disconnect_confirmed = false
      try {
        let response = await axios.get(`/code/list/`, {
          params: {
            number: this.expense_code.number,
            forwarder: this.forwarder_id,
            status: this.expense_code.number === (this.code || {number: ''}).number ? this.expense_code.status || 'checking' : status || 'checking'
          }
        })
        this.code_options = response.data.results.map(i => {
          return {
            value: i.id,
            label: i.number
          }
        })
      } catch {
        this.code_options = []
      }
    },

    async updateCodeNumber(option) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
      });

      this.code_in_focus = false
      this.is_performing_action = true
      try {
        await axios.put(`/code/connect_code_order/`, {
          actual_cost_id: this.actual_cost_id,
          code_id: option.value,
          order_child_type: 'container_order'
        })
        this.$emit('updated')
        this.is_performing_action = false
        this.expense_code.number = option.label
        this.expense_code.id = option.value

        await Toast.fire({
          title: 'Code connected successfully',
          icon: 'success'
        })
      } catch {
        this.is_performing_action = false
        this.code_in_focus = true

        await Toast.fire({
          title: 'Oops...',
          text: 'Couldn\'t connect code',
          icon: 'error'
        })

      }
    },

    async disconnectCode() {
      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
      });

      this.code_in_focus = false
      this.is_performing_action = true
      try {
        await axios.put(`/code/disconnect_code_order/`, {
          actual_cost_id: this.actual_cost_id,
          order_child_type: 'container_order'
        })
        this.$emit('updated')
        this.is_performing_action = false
        this.expense_code.number = ''
        this.expense_code.id = undefined
        this.code_disconnect_confirmed = false
        await Toast.fire({
          title: 'Code disconnected successfully',
          icon: 'success'
        })
      } catch {
        this.is_performing_action = false
        this.code_in_focus = true

        await Toast.fire({
          title: 'Oops...',
          text: 'Couldn\'t disconnect code',
          icon: 'error'
        })
      }
    },

    onCodeInputBlur() {
      this.expense_code.number = this.expense_code.number === (this.code || {
        number: ''
      }).number ? this.expense_code.number : this.code.number || ''

      this.code_disconnect_confirmed = false
    }
  },
  watch: {
    actual_cost: {
      handler(newVal) {
        this.actual_actual_cost = newVal ? parseFloat(newVal) : '';
      },
      immediate: true,
    },
    actual_actual_cost: {
      handler() {
        this.has_unsaved_changes = parseFloat(this.actual_actual_cost) !== parseFloat(this.actual_cost)
      },
      immediate: true
    },
    code: {
      handler(newValue) {
        this.expense_code = newValue ? {
          ...newValue
        } : {number: ''}
      },
      immediate: true
    },
    code_in_focus: {
      handler(newValue) {
        if (newValue) {
          this.searchCodeNumbers(this.expense_code.status ? this.expense_code.status : 'checking')
        }
      }
    },
  },
  mounted() {
    this.preferences.autosave_inputs = localStorage.getItem('autosave_inputs') ? JSON.parse(localStorage.getItem('autosave_inputs')) === true : true
  }
}
</script>

<template>

  <div @click.stop="code_in_focus = false" v-if="code_in_focus" class="overlay"></div>

  <div v-if="value_option === 'actual_costs'" class="y position-relative overflow-hidden">

    <input v-model="actual_actual_cost" type="number" class="form-control form-control-sm" :class="{
    'border border-warning border-2': has_unsaved_changes,
    'border-0': !has_unsaved_changes,
         }" placeholder="Expense"
           v-on:keyup.enter="updateActualCost(false)"
           @blur="onInputBlur()"
    >

    <span v-if="has_unsaved_changes"
          v-b-tooltip.hover title="Unsaved changes"
          class="position-absolute top-0 translate-middle" style="right: -7px">
      <i class="mdi mdi-circle text-warning" style="font-size: 10px"></i>
    </span>

    <div v-if="is_performing_action" v-b-tooltip.hover title="Loading..."
         class="position-absolute w-100 overflow-hidden"
         style="left: 0; bottom: 1px">
      <span class="loader"></span>
    </div>

  </div>

  <div v-else-if="value_option === 'codes'" class="position-relative" :style="{zIndex: code_in_focus ? 10000 : 2}">

    <input type="text" class="form-control form-control-sm" :class="{
    'border border-warning border-2': has_unsaved_changes,
    'border-0': !has_unsaved_changes,
         }" placeholder="Code number" v-model="expense_code.number"
           @focus.stop="code_in_focus = true"
           @input="searchCodeNumbers('checking')"
           @blur="onCodeInputBlur()"
    >

    <ul v-if="code_in_focus" data-simplebar
        class="options list-unstyled position-absolute left-0 bg-light border w-100 rounded-3 overflow-scroll shadow"
        style="z-index: 10000; top: 115%; min-width: 180px; max-height: 200px">
      <li class="ps-2 position-sticky top-0 border-bottom bg-light" style="padding-bottom: 2px; z-index: 10000">
        <span class="badge text-muted fw-medium fs-10">
          {{ forwarder_name }}
        </span>
      </li>
      <template v-for="option in code_options" :key="`code_option_${option}`">
        <li @click="updateCodeNumber(option)"
            class="px-2 py-1 cursor-pointer d-flex align-items-center justify-content-between gap-2" style="z-index: 4">

          <span class="badge" style="transition: 0.5s" :class="{
            'floating-badge badge-soft-danger border border-danger fs-10': code_disconnect_confirmed,
            'text-dark fs-11': !code_disconnect_confirmed,
          }">{{ option.label }}</span>

          <span v-if="(code && code.id) === option.value && !code_disconnect_confirmed"
                @click.stop="code_disconnect_confirmed = true"
                class="badge badge-soft-dark p-1">
            <i class="bx bx-x fs-5 align-middle text-danger fs-13"></i>
          </span>

          <div v-else-if="code_disconnect_confirmed" class="d-flex align-items-center gap-1">
            <span @click.stop="disconnectCode(option)"
                  class="badge bg-danger p-1">
              <i class="mdi mdi-check fs-5 align-middle fs-13"></i>
            </span>
            <span @click.stop="code_disconnect_confirmed = false"
                  class="badge badge-soft-dark p-1">
              <i class="bx bx-x fs-5 align-middle fs-13"></i>
            </span>
          </div>
        </li>
      </template>
    </ul>

    <div v-b-tooltip.hover title="Loading..." v-if="is_performing_action"
         class="position-absolute w-100 overflow-hidden"
         style="left: 0; bottom: 1px">
      <span class="loader"></span>
    </div>
  </div>

  <div v-else-if="value_option === 'acts'">
    <input type="text" class="form-control form-control-sm" :class="{
    'border border-warning border-2': has_unsaved_changes,
    'border-0': !has_unsaved_changes,
         }" placeholder="Act" :value="act ? act.name : ''"
    >
  </div>

</template>


<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9999; /* Ensure it is just below the options dropdown */
}

.input-container {
  position: relative;
  z-index: 6; /* Ensure it is above the overlay */
}

.input-container input,
.input-container .options {
  position: relative;
  z-index: 6; /* Ensure they are above the overlay */
}

.loader {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  position: relative;
  color: #FFF;
  left: -25px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45);
  }
  12% {
    box-shadow: 100px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45);
  }
  25% {
    box-shadow: 110px 0 rgba(10, 179, 156, 0.45), 100px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45);
  }
  36% {
    box-shadow: 120px 0 rgba(10, 179, 156, 0.45), 110px 0 rgba(10, 179, 156, 0.45), 100px 0 rgba(10, 179, 156, 0.45), 0px 0 rgba(10, 179, 156, 0.45);
  }
  50% {
    box-shadow: 130px 0 rgba(10, 179, 156, 0.45), 120px 0 rgba(10, 179, 156, 0.45), 110px 0 rgba(10, 179, 156, 0.45), 100px 0 rgba(10, 179, 156, 0.45);
  }
  62% {
    box-shadow: 200px 0 rgba(10, 179, 156, 0.45), 130px 0 rgba(10, 179, 156, 0.45), 120px 0 rgba(10, 179, 156, 0.45), 110px 0 rgba(10, 179, 156, 0.45);
  }
  75% {
    box-shadow: 200px 0 rgba(10, 179, 156, 0.45), 200px 0 rgba(10, 179, 156, 0.45), 130px 0 rgba(10, 179, 156, 0.45), 120px 0 rgba(10, 179, 156, 0.45);
  }
  87% {
    box-shadow: 200px 0 rgba(10, 179, 156, 0.45), 200px 0 rgba(10, 179, 156, 0.45), 200px 0 rgba(10, 179, 156, 0.45), 130px 0 rgba(10, 179, 156, 0.45);
  }
  100% {
    box-shadow: 200px 0 rgba(10, 179, 156, 0.45), 200px 0 rgba(10, 179, 156, 0.45), 200px 0 rgba(10, 179, 156, 0.45), 200px 0 rgba(10, 179, 156, 0.45);
  }
}


/* Webkit-based browsers (Chrome, Safari) */
.options::-webkit-scrollbar {
  width: 4px;
}

.options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.options::-webkit-scrollbar-thumb {
  background: rgba(10, 179, 156, 0.45);
  border-radius: 10px;
}

.options::-webkit-scrollbar-thumb:hover {
  background: rgba(10, 179, 156, 0.6);
}

/* Firefox */
.options {
  scrollbar-width: thin;
  scrollbar-color: rgba(10, 179, 156, 0.45) #f1f1f1;
}

/* Applying a more specific style to the container */
.options {
  scrollbar-gutter: stable;
}

.floating-badge {
  display: inline-block;
  animation: float 2s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}
</style>