<script>
import {
  SettingsIcon
} from '@zhuowenli/vue-feather-icons';

import axios from "axios";

import Container from "@/views/pages/orders/container/details/components/Container.vue"
import AgreedRate from "@/views/pages/orders/container/details/components/AgreedRate.vue"
import AdditionalCost from "@/views/pages/orders/container/details/components/AdditionalCost.vue"
import ActualCost from "@/views/pages/orders/container/details/components/ActualCost.vue"
import CounterpartyItem from "@/views/pages/orders/container/details/components/CounterpartyItem.vue"
import ContainerExplanationModal from "@/views/pages/orders/container/details/modals/ContainerExplanation.vue"
import AddExpenseModal from "@/views/pages/orders/container/details/modals/AddExpense.vue"
import ConnectCodesModal from "@/views/pages/orders/container/details/modals/ConnectCodes.vue"
import OrderUXPreferencesSettingsModal
  from "@/views/pages/orders/container/details/modals/OrderUXPreferencesSettings.vue"
import OrdersApi from "@/api/orders/orders_api";

export default {
  components: {
    SettingsIcon,

    Container,
    AgreedRate,
    AdditionalCost,
    ActualCost,
    CounterpartyItem,

    ContainerExplanationModal,
    OrderUXPreferencesSettingsModal,

    AddExpenseModal,
    ConnectCodesModal,
  },
  data() {
    return {
      showWelcomeModal: false,
      active_input_option: 'actual_costs',

      order: null,
      order_containers_search: '',
      container_hovered: {},
      counterparty_hovered: {},
      showSidebar: true,
      active_container_type: 'all',
      container_type_options: [],
      counterparty_options: [],
      category_options: [],

    };
  },
  computed: {
    order_number() {
      return this.order ? this.order[0].order.order_number : null
    },
    order_containers() {
      if (this.order) {
        let containers = []
        this.order[0].container_types.forEach((container_type) => {
          container_type.expanses
              .filter(i => i.container)
              .forEach((expanse) => {
                let container = {
                  id: expanse.id,
                  container: expanse.container,
                  additional_cost: expanse.additional_cost || "0",
                  agreed_rate: expanse.agreed_rate || "0",
                  total_expanses: expanse.actual_costs.map(i => i.actual_cost).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
                  expenses: expanse.actual_costs.map(i => {
                    return {
                      code: i.code,
                      counterparty_id: i.counterparty_id
                    }
                  })
                }
                container.profit = ((parseFloat(container.agreed_rate) + parseFloat(container.additional_cost)) - container.total_expanses).toFixed(2)
                containers.push(container)
              })
        })
        if (this.order_containers_search.trim().length > 0) {
          containers = containers.filter(container => container.container.name.toLowerCase().includes(this.order_containers_search.trim().toLowerCase()))
        }
        return containers
      }
      return []
    },
    order_invoices() {
      return this.order
          ? this.order[0].order.invoices.map((invoice) => {
            return {
              id: invoice.id,
              file: invoice.file,
              file_name: invoice.file.split('/').pop(),
              date: invoice.date,
              status: invoice.paid_status,
              total_cost: invoice.total_cost || "0",
            }
          })
          : []
    },
    order_counterparties() {
      if (this.order) {
        return this.order[0].order.counterparties.map((counterparty) => {
          let total_actual_cost = 0

          this.order_container_types.forEach(type => {
            type.expanses.forEach(expense => {
              expense.actual_costs.forEach(actual_cost => {
                if (actual_cost.counterparty_id === counterparty.id) {
                  total_actual_cost = total_actual_cost + parseFloat(actual_cost.actual_cost || 0)
                }
              })
            })
          })

          return {
            ...counterparty.counterparty,
            id: counterparty.id,
            counterparty_id: counterparty.counterparty.id,
            category: counterparty.category,
            total_actual_cost: total_actual_cost
          }
        })
      }
      return []
    },
    order_counterparties_unique_by_counterparty_id() {
      const seen = new Set();
      return this.order_counterparties.filter(counterparty => {
        const id = counterparty.counterparty_id;
        if (seen.has(id)) {
          return false;
        } else {
          seen.add(id);
          return true;
        }
      })
    },
    order_container_types() {
      let container_types = [];
      if (this.order) {

        if (this.active_container_type === 'all') {
          container_types = this.order[0].container_types;
        } else {
          container_types = this.order[0].container_types.filter(type => type.container_type === this.active_container_type)
        }

        container_types = container_types.map(type => {
          return {
            ...type,
            expanses: type.expanses.map(expense => {
              return {
                ...expense,
                total_actual_cost: expense.actual_costs.map(i => i.actual_cost).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
                profit: (parseFloat(expense.agreed_rate || '0') + parseFloat(expense.additional_cost || '0')) - expense.actual_costs.map(i => i.actual_cost).reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
              }
            }).filter(expense => {
              return this.order_containers_search.trim().length === 0 || (expense.container && expense.container.name.toLowerCase().includes(this.order_containers_search.trim().toLowerCase()));
            })
          };
        });

        return container_types;
      }
      return container_types;
    },
    total_agreed_rates() {
      return this.order_container_types.map(i => i.expanses.map(o => o.agreed_rate || "0")).flat().reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    },
    total_additional_costs() {
      return this.order_container_types.map(i => i.expanses.map(o => o.additional_cost || "0")).flat().reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    },
    total_actual_cost() {
      return this.order_counterparties.map(i => i.total_actual_cost).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    },
    total_profit() {
      let total_profit = 0
      this.order_container_types.forEach(i => {
        i.expanses.forEach(e => {
          total_profit = total_profit + e.profit
        })
      })
      return total_profit
    }
  },

  methods: {
    switchOldUI() {
      localStorage.setItem('order_detail_ui', 'old')
      window.location.reload()
    },
    async getOrder() {
      try {
        let response = await axios.get(`/container_order/list/${this.$route.params.id}/`)
        this.order = response.data
        this.container_type_options = this.order[0].container_types.map(i => i.container_type)
      } catch {
        this.order = null
      }
    },
    markAsHovered(container) {
      this.container_hovered = container
    },

    async getCounterpartyList() {
      if (this.counterparty_options.length > 0) return
      let orders = new OrdersApi()
      this.counterparty_options = (await orders.getCounterpartyList()).results || []
    },
    async getCategoryList() {
      if (this.category_options.length > 0) return;
      let orders = new OrdersApi()
      this.category_options = (await orders.getCategoryList()).results || []
    },
    getCounterpartyExpenses(counterparty) {
      try {
        let expenses = []
        this.order_container_types.forEach((container_type) => {
          container_type.expanses.forEach((expense) => {
            expenses.push({
              agreed_rate: expense.agreed_rate,
              additional_cost: expense.additional_cost,
              container: expense.container,
              container_type: container_type.container_type,
              actual_costs: expense.actual_costs.filter(i => i.origin_counterparty.id === counterparty.counterparty_id).map(actual_cost => {
                return {
                  ...actual_cost,
                  category: this.order_counterparties.find(counterparty => counterparty.id === actual_cost.counterparty_id).category
                }
              })
            })
          })
        })
        return expenses
      } catch {
        return []
      }
    },


    showConnectCodeModal(event) {
      if (event) {
        this.$refs.connectCodesModal.openModal(
            event.counterparty || null,
            event.categories || [],
            this.$route.params.id,
            this.order_containers
        )
      } else {
        alert("no event")
      }
    }
  },
  watch: {
    showSidebar(newValue) {
      localStorage.setItem('showOrderDetailsSidebar', newValue)
    },
  },
  mounted() {
    this.showSidebar = JSON.parse(localStorage.getItem('showOrderDetailsSidebar')) === true
    this.getOrder()
    this.getCounterpartyList()
    this.getCategoryList()


    try {
      if (!localStorage.getItem('trying_new_ui_first_time')) {
        this.showWelcomeModal = true
        localStorage.setItem('trying_new_ui_first_time', 'no')
      }
    } catch {
      console.log("could not check first try")
    }
  },
};
</script>

<template>

  <b-modal v-model="showWelcomeModal" body-class="p-0" header-class="p-0" hide-footer
           class="v-modal-custom" content-class="border-0 overflow-hidden" size="lg" centered
           hide-header-close>
    <b-row class="g-0">
      <b-col lg="7">
        <div class="modal-body p-5">
          <h2 class="lh-base mb-3">Welcome to our <span class="text-success">new UI!</span></h2>
          <p class="text-muted mb-4 lh-lg">
            We've made some exciting updates to enhance your experience. Here are some of the new features:
          </p>
          <ul class="list-unstyled d-flex flex-column gap-3">
            <li class="lh-lg">
              AutoSave - <span class="text-muted">
        Your input changes are now automatically saved, ensuring no loss of data.
    </span>
            </li>
            <li class="lh-lg">
              Seamless Code Integration - <span class="text-muted">
        Connecting or disconnecting codes from containers is now faster and more intuitive.
    </span>
            </li>
            <li class="lh-lg">
              Advanced Analytics - <span class="text-muted">
        View total expenses and profits per order, including detailed breakdowns by counterparty.
    </span>
            </li>
          </ul>

        </div>
      </b-col>
      <b-col lg="5">
        <div class="subscribe-modals-cover h-100">
          <img src="@/assets/images/users_img.jpg" alt=""
               class="h-100 w-100 object-cover"
               style="clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);">
        </div>
      </b-col>
    </b-row>
  </b-modal>

  <ContainerExplanationModal ref="containerExplanationModal"/>
  <OrderUXPreferencesSettingsModal ref="orderUXPreferencesSettingsModal"/>
  <AddExpenseModal
      :counterparty_options="counterparty_options"
      :category_options="category_options"
      :order_number="this.$route.params.id"
      :container_types="order_container_types.map(i => {
        return {
          id: i.id,
          container_type: i.container_type
        }
      })"
      :order_counterparties="order_counterparties"
      ref="addExpenseModal"
      @updated="getOrder()"

  />

  <ConnectCodesModal @updated="getOrder()" ref="connectCodesModal"/>


  <div class="chat-wrapper d-xl-flex gap-1 mx-n4 mt-n4 p-1" style="transition: 0.5s">

    <div v-if="showSidebar" class="chat-leftsidebar d-none d-xl-block">

      <div class="d-flex justify-content-between align-items-center gap-3 px-4 pe-3 pt-4 mb-4">
        <div class="search-box">
          <input v-model="order_containers_search" type="text" class="form-control bg-light border-light"
                 placeholder="Search containers..."/>
          <i class="ri-search-2-line search-icon"></i>
        </div>
        <div>
          <i @click="showSidebar = false" v-b-tooltip.hover title="Hide Sidebar"
             class="ri-close-fill fs-5 border p-2 rounded-3"></i>
        </div>
      </div>

      <div class="chat-room-list" data-simplebar>

        <div class="d-flex align-items-center px-4 mb-2">
          <div class="flex-grow-1">
            <h4 class="mb-0 fs-11 text-muted text-uppercase">
              Containers
            </h4>
          </div>
        </div>

        <div>
          <div v-if="order_containers.length > 0" data-simplebar style="max-height: 450px;">

            <ul class="list-unstyled chat-list chat-user-list">
              <template v-for="container in order_containers" :key="`container_list_${container.id}`">
                <li :class="{
                      'active cursor-pointer': container_hovered.id === container.container.id
                    }">
                  <a>
                    <div class="d-flex align-items-center">

                      <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                        <div class="avatar-xxs">
                          <img src="@/assets/images/cargo.png" class="rounded-circle img-fluid userprofile" alt/>
                        </div>
                      </div>

                      <div class="flex-grow-1 overflow-hidden">
                        <p class="text-truncate mb-0">
                          {{ container.container.name }}
                        </p>
                      </div>

                      <div class="flex-shrink-0">
                        <span style="transition: .15s" class="badge fs-11" :class="{
                          'text-success': parseFloat(container.profit || 0) >= 0,
                          'text-danger': parseFloat(container.profit || 0) < 0,
                          'pe-0': container_hovered.id !== container.container.id
                                 }"
                        >
                          $ {{
                            parseFloat(container.profit || 0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                          }}
                        </span>
                      </div>
                    </div>
                  </a>
                </li>
              </template>
            </ul>

          </div>
          <div v-else>
            <ul class="list-unstyled chat-list chat-user-list">
              <li class="text-danger">
                <a v-if="order_containers_search.trim().length === 0"> No Containers Found </a>
                <a v-else class="text-truncate"> No matching containers found for "{{
                    order_containers_search.trim()
                  }}" </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="px-4 mt-4 pt-2 mb-3">
          <h4 class="mb-0 fs-11 text-muted text-uppercase">Counterparties</h4>
        </div>

        <div class="chat-message-list">

          <ul class="list-unstyled chat-list chat-user-list mb-0" id="channelList">

            <li class="px-4 my-2">
              <div v-b-tooltip.hover title="Add counterparty">
                <b-button @click="this.$refs.addExpenseModal.openModal()" class="w-100" type="button"
                          variant="soft-success" size="sm"
                >
                  <i class="ri-add-line align-bottom"></i>
                  New Counterparty
                </b-button>
              </div>
            </li>
            <template v-for="(counterparty, index) in order_counterparties_unique_by_counterparty_id"
                      :key="`counterparty_list_${counterparty.id}`">
              <CounterpartyItem
                  @updated="getOrder()"
                  @showConnectCodeModal="showConnectCodeModal($event)"
                  :index="index"
                  :counterparty="counterparty"
                  :category="counterparty.category"
                  :active="counterparty_hovered.id === counterparty.id"
                  :counterparty_options="counterparty_options"
                  :category_options="category_options"
                  :expenses="getCounterpartyExpenses(counterparty)"
              />
            </template>
          </ul>
        </div>
      </div>
    </div>

    <div class="user-chat w-100 overflow-hidden">
      <div class="chat-content">
        <div class="w-100 ">
          <div class="">

            <div class="p-3 user-chat-topbar">
              <b-row class="align-items-center" gutter-y="3">
                <b-col sm="4" cols="8">
                  <div class="d-flex align-items-center gap-3">

                    <div v-if="!showSidebar">
                      <i @click="showSidebar = true" v-b-tooltip.hover title="Show Sidebar"
                         class="ri-arrow-right-line fs-5 border p-2 rounded-3"></i>
                    </div>

                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0 align-self-center me-3 ms-0">
                        <img :src="require('@/assets/images/order-diamond-icon.webp')"
                             class="rounded-circle avatar-xs" alt=""/>
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="text-truncate mb-0 fs-16">
                          <b-link class="text-reset">
                            {{ order_number }}
                          </b-link>
                        </h5>
                        <p class="text-truncate text-muted fs-14 mb-0 userStatus">
                          <small>Order Number</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col sm="8" cols="4">
                  <ul class="list-inline user-chat-nav text-end mb-0">

                    <li class="list-inline-item d-none d-lg-inline-block m-0">
                      <b-button @click="switchOldUI()" variant="outline-dark">
                        <i class="mdi mdi-chevron-left me-1 fw-bolder"></i>
                        Switch Old UI
                      </b-button>
                    </li>

                    <li class="list-inline-item d-none d-lg-inline-block m-0">
                      <b-button @click="this.$refs.orderUXPreferencesSettingsModal.openModal()"
                                type="button" variant="ghost-secondary" class="btn-icon" data-bs-toggle="offcanvas"
                                data-bs-target="#userProfileCanvasExample" aria-controls="userProfileCanvasExample">
                        <SettingsIcon class="icon-sm"></SettingsIcon>
                      </b-button>
                    </li>

                  </ul>
                </b-col>
                <b-col sm="12">
                  <div class="d-flex justify-content-between align-items-center py-2 flex-wrap">
                    <ul class="nav nav-tabs nav-tabs-custom rounded card-header-tabs border-bottom-0 text-muted"
                        role="tablist" aria-orientation="horizontal">
                      <li class="nav-item" role="presentation">
                        <button @click="active_container_type = 'all'" :class="{
                        'active': active_container_type === 'all'
                      }"
                                class="nav-link p-2 rounded-0" style="font-size: 12px" role="tab">
                          All
                        </button>
                      </li>
                      <template v-for="container_type in container_type_options"
                                :key="`container_type_${container_type}`">
                        <li class="nav-item" role="presentation">
                          <button @click="active_container_type = container_type" :class="{
                        'active': active_container_type === container_type
                      }"
                                  class="nav-link p-2 rounded-0" style="font-size: 12px" role="tab">
                            {{ container_type }}
                          </button>
                        </li>
                      </template>
                    </ul>
                    <ul class="nav nav-tabs nav-tabs-custom rounded card-header-tabs border-bottom-0 text-muted"
                        role="tablist" aria-orientation="horizontal">
                      <li class="nav-item" role="presentation">
                        <button @click="active_input_option = 'actual_costs'" :class="{
                        'active': active_input_option === 'actual_costs'
                      }"
                                class="nav-link p-2 rounded-0" style="font-size: 12px" role="tab">
                          Actual Costs
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button @click="active_input_option = 'codes'" :class="{
                        'active': active_input_option === 'codes'
                      }"
                                class="nav-link p-2 rounded-0" style="font-size: 12px" role="tab">
                          Codes
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button @click="active_input_option = 'acts'" :class="{
                        'active': active_input_option === 'acts'
                      }"
                                class="nav-link p-2 rounded-0" style="font-size: 12px" role="tab">
                          Acts
                        </button>
                      </li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
            </div>

            <div>
              <div class="chat-conversation overflow-scroll">

                <div class="table-responsive p-2">
                  <table class="table table-wrap mb-0">
                    <thead>
                    <tr class="align-middle">
                      <th style="max-width: 200px; min-width: 150px" scope="col">
                        <div class="border p-2 rounded-3">Container</div>
                      </th>
                      <th style="max-width: 200px; min-width: 100px" scope="col">
                        <div class="border p-2 rounded-3">Agreed Rate</div>
                      </th>
                      <th style="max-width: 200px; min-width: 100px" scope="col">
                        <div class="border p-2 rounded-3">Additional Cost</div>
                      </th>
                      <template v-for="counterparty in order_counterparties" :key="`counterparty_${counterparty}`">
                        <th style="max-width: 80px" scope="col">
                          <div class="d-flex flex-column gap-2 px-2">
                            <span v-b-tooltip.hover :title="counterparty.category.name"
                                  class="badge badge-soft-success text-truncate">
                              {{ counterparty.category.name }}
                            </span>
                            <span v-b-tooltip.hover :title="counterparty.name" class="text-truncate">
                              {{ counterparty.name }}
                            </span>
                          </div>
                        </th>
                      </template>
                      <th class="text-center" style="max-width: 80px">
                        <div class="border p-2 rounded-3">
                          Total Expanses
                        </div>
                      </th>
                      <th class="text-center" style="max-width: 80px">
                        <div class="border p-2 rounded-3">
                          Profit
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <template v-for="container_type in order_container_types" :key="`container_type_${container_type}`">
                      <tbody>
                      <tr class="align-middle">
                        <th class="py-2" :colspan="5 + order_counterparties.length">
                          <span v-b-tooltip.hover title="Container type"
                                class="badge badge-soft-success ms- w-100 py-2">{{
                              container_type.container_type
                            }}</span>
                        </th>
                      </tr>
                      <template v-for="(expense, expense_index) in (container_type.expanses || [])"
                                :key="`expense_${expense.id}`">
                        <tr
                            :class="{
                          'bg-soft-success': expense.container && (expense.container.id === container_hovered.id),
                        }"
                        >
                          <th style="max-width: 200px; min-width: 150px" scope="row">
                            <Container
                                @show_container_explanation_modal="this.$refs.containerExplanationModal.openModal()"
                                @updated="getOrder()"
                                :expense_id="expense.id"
                                :container_type_id="container_type.id"
                                :container="expense.container ? expense.container : {name: ''}"
                            />
                          </th>
                          <td style="max-width: 200px; min-width: 100px">
                            <AgreedRate
                                @updated="getOrder()"
                                :expense_id="expense.id"
                                :agreed_rate="expense.agreed_rate"

                                :index="expense_index"
                                :container_type_id="container_type.id"
                                :container_type="container_type.container_type"
                                :container="expense.container ? expense.container : {name: ''}"
                            />
                          </td>
                          <td style="border-right: 2px solid rgba(10, 179, 156, 0.45) !important; max-width: 200px; min-width: 100px">
                            <AdditionalCost
                                @updated="getOrder()"
                                :expense_id="expense.id"
                                :additional_cost="expense.additional_cost"

                                :index="expense_index"
                                :container_type_id="container_type.id"
                                :container_type="container_type.container_type"
                                :container="expense.container ? expense.container : {name: ''}"
                            />
                          </td>
                          <template v-for="actual_cost in expense.actual_costs" :key="`actual_cost_${actual_cost}`">
                            <td style="max-width: 200px; min-width: 100px" :class="{
                              'bg-soft-danger': actual_cost.counterparty_id === counterparty_hovered.id
                            }">
                              <ActualCost
                                  @updated="getOrder()"
                                  :actual_cost_id="actual_cost.id"
                                  :counterparty_id="actual_cost.counterparty_id"
                                  :actual_cost="actual_cost.actual_cost || ''"
                                  :index="expense_index"
                                  :container_type_id="container_type.id"
                                  :code="actual_cost.code"
                                  :act="actual_cost.act"
                                  :value_option="active_input_option"
                                  :forwarder_name="actual_cost.origin_counterparty.name"
                                  :forwarder_id="actual_cost.origin_counterparty.id"
                              />
                            </td>
                          </template>
                          <td style="max-width: 200px; min-width: 100px">
                            <input class="form-control form-control-sm border-0 text-center" readonly
                                   :value="'$ ' + parseFloat(expense.total_actual_cost || '0').toLocaleString(undefined, {
                                     minimumFractionDigits: 2,
                                     maximumFractionDigits: 2
                                   })"
                                   :class="{
                          'bg-danger text-light fw-medium': expense.container && (expense.container.id === container_hovered.id),
                          'bg-soft-danger': !expense.container || (expense.container.id !== container_hovered.id),
                        }"
                            >
                          </td>
                          <td style="max-width: 200px; min-width: 100px">
                            <input v-b-tooltip.hover :title="`${(parseFloat(expense.agreed_rate) + parseFloat(expense.additional_cost)).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })} - ${parseFloat(expense.profit || '0').toLocaleString(undefined, {
                                     minimumFractionDigits: 2,
                                     maximumFractionDigits: 2
                            })}`"
                                   :class="{
                          'bg-success text-light fw-medium': (expense.container && (expense.container.id === container_hovered.id)) && parseFloat(expense.profit || '0') >= 0,
                          'bg-soft-success': (!expense.container || (expense.container.id !== container_hovered.id)) && parseFloat(expense.profit || '0') >= 0,

                          'bg-danger text-light fw-medium': (expense.container && (expense.container.id === container_hovered.id)) && parseFloat(expense.profit || '0') < 0,
                          'bg-soft-danger': (!expense.container || (expense.container.id !== container_hovered.id)) && parseFloat(expense.profit || '0') < 0,
                        }"
                                   class="form-control form-control-sm border-0 text-center" readonly
                                   :value="'$ ' + parseFloat(expense.profit || '0').toLocaleString(undefined, {
                                     minimumFractionDigits: 2,
                                     maximumFractionDigits: 2
                                   })">
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </template>
                  </table>
                </div>

              </div>
            </div>

            <div class="chat-input-section px-2 py-1">
              <div class="table-responsive py-0">
                <table class="table table-wrap mb-0 table-borderless">
                  <thead>
                  <tr>
                    <th style="max-width: 200px; min-width: 150px" scope="col">
                      <input class="form-control form-control-sm border-0"
                             readonly
                             :value="order_container_types.map(i => i.expanses.length).reduce((a, b) => a + b, 0) + ' containers'">
                    </th>
                    <th style="max-width: 200px; min-width: 100px" scope="col">
                      <input v-b-tooltip.hover title="Total Agreed Rates" class="form-control form-control-sm border-0"
                             readonly
                             :value="'$ ' + parseFloat(total_agreed_rates || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })">
                    </th>
                    <th style="max-width: 200px; min-width: 100px" scope="col">
                      <input v-b-tooltip.hover title="Total Additional Costs"
                             class="form-control form-control-sm border-0" readonly
                             :value="'$ ' + parseFloat(total_additional_costs || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })">
                    </th>
                    <template v-for="counterparty in order_counterparties" :key="`counterparty_${counterparty}`">
                      <th style="max-width: 200px; min-width: 100px" scope="col">
                        <input class="form-control form-control-sm border-0" readonly
                               :value="'$ ' + parseFloat(counterparty.total_actual_cost || '0').toLocaleString(undefined, {
                                 minimumFractionDigits: 2,
                                 maximumFractionDigits: 2
                               })"
                               :class="{
                              'bg-danger text-light fw-medium': counterparty.id === counterparty_hovered.id,
                              'bg-soft-danger': counterparty.id !== counterparty_hovered.id,
                            }">
                      </th>
                    </template>
                    <th style="max-width: 200px; min-width: 100px">
                      <input v-b-tooltip.hover title="Total Expense"
                             class="form-control form-control-sm bg-transparent text-center fw-bold text-danger border-danger"
                             readonly
                             :value="'$ ' + parseFloat(total_actual_cost || '0').toLocaleString(undefined, {
                                 minimumFractionDigits: 2,
                                 maximumFractionDigits: 2
                             })"
                      >
                    </th>
                    <th style="max-width: 200px; min-width: 100px">
                      <input v-b-tooltip.hover title="Total Profit"
                             class="form-control form-control-sm bg-transparent text-center fw-bold"
                             :class="{
                        'text-success border-success':parseFloat(total_profit || '0') >= 0,
                        'text-danger border-danger':parseFloat(total_profit || '0') < 0,
                             }"
                             readonly
                             :value="'$ ' + parseFloat(total_profit || '0').toLocaleString(undefined, {
                                 minimumFractionDigits: 2,
                                 maximumFractionDigits: 2
                             })"
                      >
                    </th>
                  </tr>
                  </thead>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style>
.scroller {
  height: 100%;
}
</style>