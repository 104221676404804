<template>
  <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      style="width: 650px; max-width: 100%"
  >
    <div class="offcanvas-header border-bottom">
      <h5 class="offcanvas-title" id="offcanvasRightLabel">
        Order settings
        <span class="badge badge-gradient-secondary ms-1">{{ order_number }}</span>
      </h5>
      <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
      ></button>
    </div>

    <div class="offcanvas-body p-0 overflow-hidden">
      <div data-simplebar style="height: calc(100vh - 112px);">
        <div class="card shadow-none my-0">
          <div class="card-header bg-light py-1"></div>
        </div>

        <div @click.stop="getContainersInTerminals()" class="card mb-0 shadow-none">
          <div class="card-header py-2" type="button" data-bs-toggle="collapse" href="#containersInTerminal">
            <div class="row align-items-center w-100 m-auto">
              <div class="col-11 px-0 mx-0">
                <p class="card-title mb-0 py-2">
                  <span class="badge badge-gradient-secondary me-2">
                    <i class="mdi mdi-star align-middle"></i>
                  </span>
                  Containers in Terminals
                </p>
              </div>
              <div class="col-1 text-center px-0 mx-0">
                <font-awesome-icon icon="fa-solid fa-angle-down"/>
              </div>
            </div>
          </div>
          <div class="card-body p-0 border-0" @click.stop="null">
            <b-collapse class="collapse border-bottom shadow-none" id="containersInTerminal">

              <div class="p-3">
                <b-button :disabled="containers_in_terminal.data.filter(i => i.selected).length === 0"
                          variant="soft-danger" class="w-100 waves-effect waves-light mb-3 fw-medium"
                          @click.stop="disconnectSelectedContainersFromTerminal()"
                >
                  Disconnect selected containers
                </b-button>

                <div class="mb-3">
                  <input v-model="search_query" class="form-control" placeholder="Search...">
                </div>

                <p v-if="containers_in_terminal.data.length > 0 && containers_in_terminal.data.every(i => i.selected)"
                   @click.stop="unselectAllContainersToDisconnectFromTerminal()"
                   class="mb-2 text-decoration-underline cursor-pointer">Unselect all container</p>
                <p v-else-if="containers_in_terminal.data.length > 0"
                   @click.stop="selectAllContainersToDisconnectFromTerminal()"
                   class="mb-2 text-decoration-underline cursor-pointer">Select all container</p>

                <div class="table-responsive">
                  <table class="table table-nowrap">
                    <thead>
                    <tr class="text-center align-middle">
                      <th scope="col" class="text-start">Container</th>
                      <th scope="col">Arrival Date</th>
                      <th scope="col">Dispatch Date</th>
                      <th scope="col">Terminal</th>
                      <th scope="col">Manager</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(container, index) in containers_in_terminal_filtered"
                              :key="`container_in_terminal_${container}_${index}`">
                      <tr class="text-center align-middle">
                        <td>
                          <div class="form-check form-check-danger">
                            <input v-model="container.selected" @change="selectContainer(container, index)"
                                   class="form-check-input" type="checkbox"
                                   :id="`container_${container.container_id}_in_terminal_${container.terminal_id}`"
                                   :checked="container.selected">
                            <label class="form-check-label ps-2"
                                   :for="`container_${container.container_id}_in_terminal_${container.terminal_id}`">
                              {{ container.container }}
                            </label>
                          </div>
                        </td>
                        <td>{{ container.arrival_date || '-' }}</td>
                        <td>{{ container.dispatch_date || '-' }}</td>
                        <td>
                          <span class="badge" :class="{
                          'text-decoration-line-through bg-danger': container.selected,
                          'bg-success': !container.selected,
                        }">{{ container.terminal }}</span>
                        </td>
                        <td>
                          {{ container.manager ? container.manager : '-' }}
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>

            </b-collapse>
          </div>
        </div>
        <div class="card mb-0 shadow-none">
          <div class="card-header py-2" type="button"
               data-bs-toggle="collapse" href="#containersCodes"
          >
            <div class="row align-items-center w-100 m-auto">
              <div class="col-11 px-0 mx-0">
                <p class="card-title mb-0 py-2">
                  <span class="badge badge-gradient-secondary me-2">
                    <i class="mdi mdi-star align-middle"></i>
                  </span>
                  Containers Codes
                </p>
              </div>
              <div class="col-1 text-center px-0 mx-0">
                <font-awesome-icon icon="fa-solid fa-angle-down"/>
              </div>
            </div>
          </div>
          <div class="card-body p-0 border-0" @click.stop="null">
            <b-collapse class="collapse border-bottom shadow-none" id="containersCodes">

              <div class="p-3">
                <b-button :disabled="selected_actual_cost_ids.length === 0"
                          variant="soft-danger" class="w-100 waves-effect waves-light mb-3 fw-medium"
                          @click.stop="disconnectSelectedActualCostsFromOrder()"
                >
                  Disconnect selected codes ({{ selected_actual_cost_ids.length }})
                </b-button>

                <div class="mb-3">
                  <input v-model="actual_cost_search_query" class="form-control" placeholder="Search...">
                </div>


                <p v-if="actual_costs_computed.length > 0 && actual_costs_computed.every(i => selected_actual_cost_ids.includes(i.id))"
                   @click.stop="selected_actual_cost_ids = []"
                   class="mb-2 text-decoration-underline cursor-pointer">Unselect all</p>

                <p v-else @click.stop="selectAllActualCosts()"
                   class="mb-2 text-decoration-underline cursor-pointer">Select all</p>

                <div class="table-responsive">
                  <table class="table table-nowrap">
                    <thead>
                    <tr class="text-center align-middle">
                      <th scope="col" class="text-start">Container</th>
                      <th scope="col">Code</th>
                      <th scope="col">Counterparty / Category</th>
                      <th scope="col">Act</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(actual_cost, index) in actual_costs_computed"
                              :key="`actual_cost_${actual_cost}_${index}`">
                      <tr class="text-center align-middle">
                        <th>
                          <div class="form-check form-check-danger">
                            <input class="form-check-input" type="checkbox"
                                   @click="selectActualCost(actual_cost)"
                                   :checked="selected_actual_cost_ids.includes(actual_cost.id)"
                                   :id="`actual_cost_${actual_cost.id}`"
                            >
                            <label class="form-check-label ps-2" :for="`actual_cost_${actual_cost.id}`">
                              {{ actual_cost.container ? actual_cost.container.name : '-' }}
                            </label>
                          </div>
                        </th>
                        <td>
                          {{ actual_cost.code.number }}
                        </td>
                        <td>
                          {{
                            actual_cost.counterparty + ' / ' + actual_cost.category
                          }}
                        </td>
                        <td>
                          {{ actual_cost.act ? actual_cost.act.name : '' }}
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>

            </b-collapse>
          </div>
        </div>

        <div class="card shadow-none my-0">
          <div class="card-header bg-light py-1"></div>
        </div>

        <div class="card mb-0 shadow-none">
          <div class="card-header py-2" type="button" data-bs-toggle="collapse" href="#containerCreate">
            <div class="row align-items-center w-100 m-auto">
              <div class="col-11 px-0 mx-0">
                <p class="card-title mb-0 py-2">
                  Create container
                </p>
              </div>
              <div class="col-1 text-center px-0 mx-0">
                <font-awesome-icon icon="fa-solid fa-angle-down"/>
              </div>
            </div>
          </div>
          <div class="card-body text-center p-0 border-0">
            <b-collapse class="collapse border-bottom shadow-none" id="containerCreate">

              <div class="p-3">

                <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
                  <li class="nav-item" v-for="(container_type, tab_index) in container_types" :key="container_type">
                    <a class="nav-link" :class="tab_index === 0 ? 'active' : ''"
                       data-bs-toggle="tab" :href="'#container_type_create_' + tab_index + 10" role="tab">
                      {{ container_type.type }}
                    </a>
                  </li>
                </ul>
                <div class="tab-content text-start">
                  <div v-for="(container_type, tab_index) in container_types_with_container_key" :key="container_type"
                       class="tab-pane" :class="tab_index === 0 ? 'active' : ''"
                       :id="'container_type_create_' + tab_index + 10" role="tabpanel">

                    <div class="row w-100 m-auto">
                      <div class="col-12 px-0 mb-3">
                        <label class="form-label">
                          Containers List
                        </label>
                        <textarea v-model="container_type.containers" class="form-control"
                                  :placeholder="'Container list for ' + container_type.type"
                                  :rows="container_type.quantity >= 20 ? container_type.quantity / 2 : container_type.quantity"
                        ></textarea>
                        <div class="form-text"> One container for each line</div>
                      </div>
                      <div class="col-12 text-end px-0">
                        <b-button @click="createContainer(container_type.id, container_type.containers)"
                                  variant="success" class="btn-icon waves-effect waves-light w-100">
                          Start uploading
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </b-collapse>
          </div>
        </div>
        <div class="card mb-0 shadow-none">
          <div class="card-header py-2" type="button" data-bs-toggle="collapse" href="#counterpartyCreate">
            <div class="row align-items-center w-100 m-auto">
              <div class="col-11 px-0 mx-0">
                <p class="card-title mb-0 py-2">
                  Create counterparty
                </p>
              </div>
              <div class="col-1 text-center px-0 mx-0">
                <font-awesome-icon icon="fa-solid fa-angle-down"/>
              </div>
            </div>
          </div>
          <div class="card-body text-center p-0 border-0">
            <b-collapse class="collapse border-bottom shadow-none" id="counterpartyCreate">

              <div class="p-3">

                <div class="row text-start w-100 m-auto">
                  <div class="col-6 ps-0">
                    <div class="mb-3">
                      <label class="form-label">Counterparty</label>
                      <Multiselect
                          v-model="counterparty"
                          placeholder="Select counterparty"
                          :options="counterparty_list_options"
                          :searchable="true"
                      />
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div class="mb-3">
                      <label class="form-label">Category</label>
                      <Multiselect
                          v-model="category"
                          placeholder="Select category"
                          :options="category_list_options"
                          :searchable="true"
                      />
                    </div>
                  </div>
                </div>

                <b-list-group class="mt-2">
                  <b-list-group-item
                      v-for="container_type in container_types_computed" :key="container_type"
                      class="d-flex justify-content-between align-items-center p-2 pe-3">
                    <input
                        v-on:keyup.enter="createCounterparty()"
                        v-model="container_type.cost" class="form-control w-75 m-0 p-1 border-0" type="number"
                        placeholder="Preliminary cost">
                    <span class="badge bg-success m-0">{{ container_type.type }}</span>
                  </b-list-group-item>
                  <b-button @click="createCounterparty()"
                            variant="success" class="w-100 waves-effect waves-light mt-3">
                    Create
                  </b-button>
                </b-list-group>
              </div>

            </b-collapse>
          </div>
        </div>

        <div class="card shadow-none my-0">
          <div class="card-header bg-light py-1"></div>
        </div>

        <div class="card mb-0 shadow-none">
          <div class="card-header py-2" type="button"
               data-bs-toggle="collapse" href="#updateAgreedRate">
            <div class="row align-items-center w-100 m-auto">
              <div class="col-11 px-0 mx-0">
                <p class="card-title mb-0 py-2">
                  Agreed Rate
                </p>
              </div>
              <div class="col-1 text-center px-0 mx-0">
                <font-awesome-icon icon="fa-solid fa-angle-down"/>
              </div>
            </div>
          </div>
          <div class="card-body text-center p-0 border-0">
            <b-collapse class="collapse border-bottom shadow-none" id="updateAgreedRate">

              <div class="p-3">

                <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
                  <li class="nav-item" v-for="(container_type, tab_index) in container_types_computed"
                      :key="container_type">
                    <a class="nav-link" :class="tab_index === 0 ? 'active' : ''"
                       data-bs-toggle="tab" :href="'#update_agreed_rate_' + tab_index + 10" role="tab">
                      {{ container_type.type }}
                    </a>
                  </li>
                </ul>
                <div class="tab-content text-start">
                  <div v-for="(container_type, tab_index) in container_types_computed" :key="container_type"
                       class="tab-pane" :class="tab_index === 0 ? 'active' : ''"
                       :id="'update_agreed_rate_' + tab_index + 10" role="tabpanel">

                    <div class="row w-100 m-auto">
                      <div class="col-12 px-0">
                        <label class="form-label">
                          Agreed Rate
                        </label>
                        <form @submit.prevent="updateAgreedRate('agreed_rate', container_type)" class="row">
                          <div class="col-10">
                            <input type="number" class="form-control"
                                   v-model="container_type.agreed_rate"
                                   :placeholder="`Agreed rate for ${container_type.type}`"
                                   step="any"
                            >
                          </div>
                          <div class="col-2 ps-0">
                            <b-button variant="success" type="submit"
                                      class="btn-icon waves-effect waves-light w-100">
                              <i class="ri-check-double-line"></i>
                            </b-button>
                          </div>
                        </form>
                        <div class="form-text">
                          Applies to all containers of this type
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </b-collapse>
          </div>
        </div>
        <div class="card mb-0 shadow-none">
          <div class="card-header py-2" type="button"
               data-bs-toggle="collapse" href="#updateAddCost">
            <div class="row align-items-center w-100 m-auto">
              <div class="col-11 px-0 mx-0">
                <p class="card-title mb-0 py-2">
                  Additional Cost
                </p>
              </div>
              <div class="col-1 text-center px-0 mx-0">
                <font-awesome-icon icon="fa-solid fa-angle-down"/>
              </div>
            </div>
          </div>
          <div class="card-body text-center p-0 border-0">
            <b-collapse class="collapse border-bottom shadow-none" id="updateAddCost">

              <div class="p-3">

                <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
                  <li class="nav-item" v-for="(container_type, tab_index) in container_types_computed"
                      :key="container_type">
                    <a class="nav-link" :class="tab_index === 0 ? 'active' : ''"
                       data-bs-toggle="tab" :href="'#update_add_cost_' + tab_index + 10" role="tab">
                      {{ container_type.type }}
                    </a>
                  </li>
                </ul>
                <div class="tab-content text-start">
                  <div v-for="(container_type, tab_index) in container_types_computed" :key="container_type"
                       class="tab-pane" :class="tab_index === 0 ? 'active' : ''"
                       :id="'update_add_cost_' + tab_index + 10" role="tabpanel">

                    <div class="row w-100 m-auto">
                      <div class="col-12 px-0">
                        <label class="form-label">
                          Additional Cost
                        </label>
                        <form @submit.prevent="updateAgreedRate('additional_cost', container_type)" class="row">
                          <div class="col-10">
                            <input type="number" class="form-control"
                                   v-model="container_type.additional_cost"
                                   :placeholder="`Additional cost for ${container_type.type}`"
                                   step="any"
                            >
                          </div>
                          <div class="col-2 ps-0">
                            <b-button variant="success" type="submit"
                                      class="btn-icon waves-effect waves-light w-100">
                              <i class="ri-check-double-line"></i>
                            </b-button>
                          </div>
                        </form>
                        <div class="form-text">
                          Applies to all containers of this type
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </b-collapse>
          </div>
        </div>


        <div class="card my-0 shadow-none">
          <div class="card-header bg-light py-1"></div>
        </div>

        <div v-for="(counterparty, i) in counterparties_with_actual_cost"
             :key="counterparty.id" class="card mb-0 shadow-none">
          <div class="card-header py-2" type="button" data-bs-toggle="collapse" :href="'#counterpartyCollapse' + i + 1">
            <div class="row align-items-center w-100 m-auto">
              <div class="col-11 px-0 mx-0">
                <p class="card-title mb-0">
                  {{ counterparty.counterparty }}
                </p>
                <span class="badge badge-gradient-info">{{ counterparty.category }}</span>
              </div>
              <div class="col-1 text-center px-0 mx-0">
                <font-awesome-icon icon="fa-solid fa-angle-down"/>
              </div>
            </div>
          </div>
          <div class="card-body text-center p-0 border-0">
            <b-collapse class="collapse border-bottom shadow-none" :id="'counterpartyCollapse' + i + 1">

              <div class="p-3">

                <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
                  <li class="nav-item" v-for="(container_type, tab_index) in container_types_computed"
                      :key="container_type">
                    <a class="nav-link" :class="tab_index === 0 ? 'active' : ''"
                       data-bs-toggle="tab" :href="'#container_type_' + tab_index + '_' + i" role="tab">
                      {{ container_type.type }}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" :href="'#container_type_delete_' + counterparty.id"
                       role="tab">
                      <font-awesome-icon icon="fa-solid fa-trash" class="text-danger"/>
                    </a>
                  </li>
                </ul>

                <div class="tab-content text-start">
                  <div v-for="(container_type, tab_index) in container_types_computed" :key="container_type"
                       class="tab-pane" :class="tab_index === 0 ? 'active' : ''"
                       :id="'container_type_' + tab_index + '_' + i" role="tabpanel">

                    <div class="row w-100 m-auto">
                      <div class="col-10 ps-0">
                        <input step="any"
                               v-model="counterparty.actual_cost" class="form-control" type="number"
                               :placeholder="'Actual cost for ' + container_type.type"
                               v-on:keyup.enter="saveActualCost(counterparty.id, container_type.id, counterparty.actual_cost)"
                        >
                      </div>
                      <div class="col-2 text-end px-0">
                        <b-button variant="success" class="btn-icon waves-effect waves-light w-100"
                                  @click="saveActualCost(counterparty.id, container_type.id, counterparty.actual_cost)">
                          <i class="ri-check-double-line"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane pb-0" :id="'container_type_delete_' + counterparty.id" role="tabpanel">
                    <div class="row w-100 m-auto align-items-center pb-0">
                      <div class="col-10 ps-0">
                        <input v-model="counterparty.delete_confirmation_string" class="form-control" type="text"
                               :placeholder="counterparty.counterparty + '/' + counterparty.category">
                      </div>
                      <div class="col-2 text-end px-0">
                        <b-button
                            @click="deleteCounterparty(counterparty)"
                            variant="danger" class="btn-icon waves-effect waves-light w-100">
                          <i class="ri-delete-bin-5-line"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <div class="offcanvas-foorter border p-3 text-center">
      <a href="javascript:void(0);" class="link-success">
        View All Acitivity
        <i
            class="ri-arrow-right-s-line align-middle ms-1"
        ></i>
      </a>
    </div>
  </div>

</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";


export default {
  name: "CounterpartyActions",
  emits: ["updatedContainers", "update"],
  data() {
    return {
      counterparty: null,
      category: null,
      containers_in_terminal: {
        request_status: '',
        data: [],
        canvas_opening: true
      },
      shiftKeyPressed: false,
      last_selected_container_index: -1,

      search_query: '',
      actual_cost_search_query: '',
      selected_actual_cost_ids: []
    }
  },
  props: {
    order_number: {
      type: Number,
    },
    counterparties: {
      type: Array,
      default: () => []
    },
    container_types: {
      type: Array,
      default: () => [],
    },
    counterparty_list: {
      type: Array,
      default: () => [],
    },
    category_list: {
      type: Array,
      default: () => [],
    },
    actual_costs: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  components: {Multiselect},
  methods: {
    handleKeydown(event) {
      if (event.key === 'Shift') {
        this.shiftKeyPressed = true
      }
    },
    handleKeyup(event) {
      if (event.key === 'Shift') {
        this.shiftKeyPressed = false
      }
    },
    async showResult(ok, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (ok) {
        await Toast.fire({
          icon: 'success',
          title: title || 'Success'
        })
      } else {
        await Toast.fire({
          icon: 'error',
          title: title || 'Something went wrong'
        })
      }
    },
    async saveActualCost(counterparty_id, container_type_id, actual_cost) {

      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      if (actual_cost === null || actual_cost === undefined || actual_cost < 0) {
        await Toast.fire({
          icon: 'error',
          title: 'Actual cost must be a number'
        })
        return
      }

      try {
        await axios.put(`/container_order/expanse/actual_cost_to_all/`, {
          "container_type_id": container_type_id,
          "counterparty_id": counterparty_id,
          "actual_cost": actual_cost
        });
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'Actual cost for all containers has been updated'
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Something went wrong'
        })
      }
    },
    async createContainer(container_type_id, containers) {
      try {
        await axios.put(`/container_order/expanse/container_to_all/`, {
          "container_type_id": container_type_id,
          "containers": containers.split('\n')
        });
        this.$emit('updatedContainers')
        await this.showResult(true, 'Containers has been created')
      } catch {
        await this.showResult(false)
      }
    },
    async createCounterparty() {

      let empty_inputs = this.container_types_computed.filter(container_type =>
          container_type.cost.toString().trim() === '' || this.counterparty === null || this.category === null)

      if (empty_inputs.length > 0) {
        await this.showResult(false, 'All fields must be filled')
      } else {
        let data = {
          order_number: this.order_number,
          counterparty_id: this.counterparty,
          category_id: this.category,
          preliminary_costs: this.container_types_computed.map((container_type) => {
            return {
              container_type_id: container_type.id,
              preliminary_cost: container_type.cost
            }
          })
        }

        try {
          await axios.post(`/container_order/expanse/counterparty_add/`, data);
          this.$emit('update')
          this.counterparty = null
          this.category = null
          await this.showResult(true, 'Counterparty has been created')
        } catch (err) {
          await this.showResult(false)
        }
      }
    },
    async updateAgreedRate(field, container_type) {
      if (!field) return
      try {
        await axios.put(`/container_order/expanse/update_agreed_rate/all/${container_type.id}/`, {
          [field]: container_type[field] || 0
        })
        this.$emit('update')
        await this.showResult(true, 'Successfully updated')
      } catch {
        await this.showResult(false)
      }
    },
    async deleteCounterparty(counterparty) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      if (counterparty.delete_confirmation_string !== (counterparty.counterparty + '/' + counterparty.category)) {
        await Toast.fire({
          icon: 'error',
          title: 'Confirmation text is not correct'
        })
      } else {
        try {
          await axios.delete(`/order/counterparty/delete/${counterparty.id}/`);
          this.$emit('update')
          await Toast.fire({
            icon: 'success',
            title: 'Counterparty has been deleted'
          })
        } catch {
          await Toast.fire({
            icon: 'error',
            title: 'Something went wrong'
          })
        }

        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
      }
    },

    async getContainersInTerminals() {
      this.containers_in_terminal.canvas_opening = !this.containers_in_terminal.canvas_opening
      if (this.containers_in_terminal.canvas_opening) return
      try {
        let response = await axios.get(`/container_order/terminal_connected_containers/${this.order_number}/`)
        this.containers_in_terminal.request_status = 'success'
        this.containers_in_terminal.data = response.data.map(item => {
          return {
            ...item,
            selected: false
          }
        })
      } catch {
        this.containers_in_terminal.request_status = 'error'
        this.containers_in_terminal.data = []
        let Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        await Toast.fire({
          icon: 'error',
          title: 'Could not get containers in terminals'
        })
      }
    },
    selectAllContainersToDisconnectFromTerminal() {
      this.containers_in_terminal.data.forEach(i => i.selected = true)
    },
    unselectAllContainersToDisconnectFromTerminal() {
      this.containers_in_terminal.data.forEach(i => i.selected = false)
    },
    async disconnectSelectedContainersFromTerminal() {
      await Swal.fire({
        title: 'Are you sure?',
        text: 'Selected containers will be disconnected from their terminals',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Disconnect',
        cancelButtonText: 'No, Keep them',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let selected_containers = this.containers_in_terminal.data.filter(i => i.selected)
          let container_ids = selected_containers.map(i => i.container_id)
          try {
            await axios.put(`/container_order/terminal_disconnect_containers/${this.order_number}/`, {
              container_ids: container_ids
            })
            this.$emit('update')
            await Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Selected containers has been disconnected',
              showConfirmButton: false,
              timer: 3000
            })
          } catch {
            await Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Something went wrong',
              showConfirmButton: false,
              timer: 3000
            })
          }
        } else {
          await Swal.fire({
            icon: 'info',
            title: 'Ok',
            text: 'We will keep them',
            showConfirmButton: false,
            timer: 3000
          })
        }
      })
    },
    selectContainer(container, index) {
      if (this.shiftKeyPressed) {
        if (container.selected) {
          this.containers_in_terminal.data.filter((ctr, i) => {
            return i >= this.last_selected_container_index && i <= index
          }).forEach(ctr => ctr.selected = true)
        } else {
          this.containers_in_terminal.data.filter((ctr, i) => {
            return i >= index
          }).forEach(ctr => ctr.selected = false)
        }
      }
      this.last_selected_container_index = index;
    },

    selectActualCost(actual_cost) {
      if (!this.selected_actual_cost_ids.includes(actual_cost.id)) {
        this.selected_actual_cost_ids.push(actual_cost.id);
      } else {
        const index = this.selected_actual_cost_ids.indexOf(actual_cost.id);
        if (index > -1) {
          this.selected_actual_cost_ids.splice(index, 1);
        }
      }
    },
    selectAllActualCosts() {
      this.selected_actual_cost_ids = this.actual_costs_computed.map(i => i.id)
    },
    async disconnectSelectedActualCostsFromOrder() {
      await Swal.fire({
        title: 'Are you sure?',
        text: 'Selected codes will be disconnected from their orders',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Disconnect',
        cancelButtonText: 'No, Keep them',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.put(`/container_order/code_disconnect/`, {
              actual_cost_ids: this.selected_actual_cost_ids
            })
            this.selected_actual_cost_ids = []
            this.$emit('update')
            await Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Selected codes has been disconnected',
              showConfirmButton: false,
              timer: 3000
            })
          } catch {
            await Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Something went wrong',
              showConfirmButton: false,
              timer: 3000
            })
          }
        } else {
          await Swal.fire({
            icon: 'info',
            title: 'Ok',
            text: 'We will keep them',
            showConfirmButton: false,
            timer: 3000
          })
        }
      })
    }
  },
  computed: {
    counterparties_with_actual_cost: {
      get() {
        return this.counterparties.map(counterparty => {
          return {
            id: counterparty.id,
            counterparty: counterparty.counterparty.name,
            category: counterparty.category.name,
            actual_cost: '',
            delete_confirmation_string: ''
          }
        })
      }
    },

    container_types_computed: {
      get() {
        return this.container_types.map(container_type => {
          return {
            id: container_type.id,
            type: container_type.type,
            cost: '',
            agreed_rate: '',
            additional_cost: ''
          }
        })
      }
    },

    container_types_with_container_key() {
      return this.container_types.map(container_type => {
        return {
          ...container_type,
          containers: ''
        }
      })
    },

    counterparty_list_options() {
      return this.counterparty_list.map(counterparty => {
        return {
          value: counterparty.id,
          label: counterparty.name,
          is_used_for_codes: counterparty.is_used_for_codes
        }
      })
    },
    category_list_options() {
      return this.category_list.map(category => {
        return {
          value: category.id,
          label: category.name,
        }
      })
    },

    containers_in_terminal_filtered() {
      if (this.search_query.trim() === '') return this.containers_in_terminal.data
      return this.containers_in_terminal.data.filter((i) => {
        return i.container.toLowerCase().includes(this.search_query.trim().toLowerCase()) ||
            (i.terminal || '-').toLowerCase().includes(this.search_query.trim().toLowerCase()) ||
            (i.manager || '-').toLowerCase().includes(this.search_query.trim().toLowerCase())
      })
    },

    actual_costs_computed() {
      return this.actual_costs.map(actual_cost => {
        let counterparty = ''
        let category = ''

        try {
          let result = this.counterparties_with_actual_cost.find(i => i.id === actual_cost.counterparty_id)
          counterparty = result.counterparty
          category = result.category
        } catch {
          counterparty = ''
        }

        return {
          ...actual_cost,
          counterparty: counterparty,
          category: category,
        }
      }).filter((actual_cost) => {
        if (this.actual_cost_search_query.trim().length > 0) {
          let query = this.actual_cost_search_query.trim().toLowerCase()
          let container_matches = (actual_cost.container && actual_cost.container.name.toLowerCase().includes(query))
          let code_matches = (actual_cost.code && actual_cost.code.number.toString().toLowerCase().includes(query))
          let act_matches = (actual_cost.act && actual_cost.act.name.toLowerCase().includes(query))
          let counterparty_matches = (actual_cost.counterparty.toLowerCase().includes(query))
          let category_matches = (actual_cost.category.toLowerCase().includes(query))

          return container_matches || code_matches || act_matches || counterparty_matches || category_matches
        } else {
          return true
        }
      })
    }
  },
  created() {
    document.addEventListener('keydown', this.handleKeydown)
    document.addEventListener('keyup', this.handleKeyup)
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown)
    document.removeEventListener('keyup', this.handleKeyup)
  },
}
</script>

<style scoped>

</style>