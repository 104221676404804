<script>
export default {
  name: "ContainerExplanation",
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" title="Shipping Container Numbers" hide-footer centered>

    <div class="mb-3">
      <img src="@/assets/images/container_number.png" alt="Container Img" class="w-100">
    </div>

    <p class="border-bottom pb-3" style="line-height: 23px">
      Shipping container numbers, also known as container tracking numbers or container codes, are unique identifying
      numbers that are assigned to each shipping container. These numbers are used to track and trace the movement of
      containers from one location to another, and to identify and manage the cargo that is being transported.
    </p>

    <h5>
      What are Shipping Container Numbers?
    </h5>

    <p style="line-height: 23px">
      Shipping container numbers typically <b>consist of four letters and seven digits</b>, and are organised into two
      main
      parts: <b>the prefix</b> and <b>the serial number</b>. The prefix, which consists of the first three letters,
      identifies the
      owner or operator of the container, such as the shipping company or leasing company. The serial number, which
      consists of the remaining seven digits, is a unique identifier that is assigned to each individual container.
    </p>

    <p style="line-height: 23px">
      For example, a typical shipping container number might look like this: CMAU1234567. In this case, the prefix "CMA"
      indicates that the container is owned by the shipping company CMA CGM, and the serial number "1234 567" is a
      unique identifier for that particular container.
    </p>

    <div>
      <b-button @click="showModal = false" variant="soft-success" class="w-100">Ok, Understand</b-button>
    </div>
  </b-modal>
</template>

<style scoped>

</style>