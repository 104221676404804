<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AdditionalCost",
  emits: ['updated'],
  props: {
    index: {
      type: Number,
      required: false,
    },
    expense_id: {
      type: Number,
      required: true,
    },
    additional_cost: {
      type: String,
      required: true,
      default: '0'
    },

    container_type_id: {
      type: Number,
      required: true,
    },
    container_type: {
      type: String,
      required: true,
    },
    container: {
      type: [Object],
      required: true
    }
  },
  data() {
    return {
      actual_additional_cost: '',
      has_unsaved_changes: false,

      preferences: {
        autosave_inputs: true
      }
    }
  },
  methods: {
    async onInputBlur() {
      if (this.preferences.autosave_inputs && this.has_unsaved_changes) {
        await this.updateAdditionalCost(true)
      }
    },
    async updateAdditionalCost(is_auto_saving = false) {

      let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
      });

      if (!this.has_unsaved_changes) {
        await Toast.fire({
          title: 'Nothing changed yet',
          icon: 'info'
        })
        return
      }

      try {
        await axios.put(`/container_order/expanse/update_agreed_rate/${this.expense_id}/`, {
          additional_cost: this.actual_additional_cost
        })

        this.has_unsaved_changes = false
        this.$emit('updated')

        if (this.index === 0) {
          await Swal.fire({
            icon: "info",
            title: "Additional Cost: $ " + parseFloat(this.actual_additional_cost).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            text: `Would you like to apply this price to all rows of container type ${this.container_type}`,
            showCancelButton: true,
            confirmButtonText: "Yes, apply all!",
            cancelButtonText: 'No',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.updateAllAdditionalCost(this.actual_additional_cost)
            }
          });
        } else {
          await Toast.fire({
            title: is_auto_saving ? 'AutoSave Successful' : 'Successfully saved',
            text: parseFloat(this.actual_additional_cost).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }),
            icon: 'success'
          })
        }
      } catch {
        await Toast.fire({
          title: 'Error',
          icon: 'error'
        })
      }
    },
    async updateAllAdditionalCost(additional_cost = 0) {
      try {
        await axios.put(`/container_order/expanse/update_agreed_rate/all/${this.container_type_id}/`, {
          additional_cost: additional_cost
        })
        this.$emit("updated")
        await Swal.fire({
          title: "Success",
          text: `All additional cost values for container type ${this.container_type} have been updated to ` + parseFloat(additional_cost || '0').toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }),
          icon: 'success'
        })
      } catch {
        if (this.container.name.trim().length > 0) {
          await Swal.fire({
            title: "Error",
            text: `The additional cost value has been successfully updated for ${this.container.name} in container type ${this.container_type}, but we encountered an error while updating the other rows. Please try again or contact support if the issue persists`,
            icon: 'error'
          })
        } else {
          await Swal.fire({
            title: "Error",
            text: `The additional cost value has been successfully updated for the first row of container type ${this.container_type}, but we encountered an error while updating the other rows. Please try again or contact support if the issue persists`,
            icon: 'error'
          })
        }
      }
    }
  },
  watch: {
    additional_cost: {
      handler(newVal) {
        this.actual_additional_cost = newVal ? parseFloat(newVal) : '';
      },
      immediate: true,
    },
    actual_additional_cost: {
      handler() {
        this.has_unsaved_changes = parseFloat(this.actual_additional_cost) !== parseFloat(this.additional_cost)
      },
      immediate: true
    }
  },
  mounted() {
    this.preferences.autosave_inputs = localStorage.getItem('autosave_inputs') ? JSON.parse(localStorage.getItem('autosave_inputs')) === true : true
  }
}
</script>

<template>
  <div class="y position-relative">

    <input v-model="actual_additional_cost" type="number" step="any"
           class="form-control form-control-sm" placeholder="Additional Cost"
           :class="{
    'border border-warning border-2': has_unsaved_changes,
    'border-0': !has_unsaved_changes,
         }"
           v-on:keyup.enter="updateAdditionalCost(false)"
           @blur="onInputBlur()"
    >

    <span v-if="has_unsaved_changes"
          v-b-tooltip.hover title="Unsaved changes"
          class="position-absolute top-0 translate-middle" style="right: -7px">
      <i class="mdi mdi-circle text-warning" style="font-size: 10px"></i>
    </span>

  </div>
</template>

<style scoped>

</style>