<template>

  <b-modal v-model="showModal" :title="`Add new row: Type ${container_type}`" hide-footer centered>
    <form @submit.prevent="addRow()">
      <div class="mb-3">
        <label for="quantity">Quantity:</label>
        <input class="form-control" type="number" v-model="quantity" required placeholder="Enter quantity">
      </div>
      <div>
        <b-button class="my-0 w-100" variant="success" type="submit" :disabled="quantity <=0 || is_loading">
          Add
          <b-spinner v-if="is_loading" class="btn-loader-item" small type="grow"/>
        </b-button>
      </div>
    </form>
  </b-modal>


  <b-button class="my-0" v-if="!is_loading" variant="outline-success" size="sm" @click="openModal()">+ Add rows
  </b-button>

</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  emits: ["update"],
  name: "AddRow",
  data() {
    return {
      is_loading: false,
      showModal: false,
      quantity: 1
    }
  },
  props: {
    container_type_id: Number,
    container_type: String
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async addRow() {
      if (this.quantity <= 0 || this.is_loading) return
      if (this.quantity > 100) {
        await Swal.fire({
          icon: 'error',
          title: 'You can not add more than 100 rows at once',
          showConfirmButton: false,
          timer: 1500
        })
        return
      }
      this.is_loading = true
      try {
        await axios.post(`/container_order/expanse/add_container/${this.container_type_id}/`, {
          quantity: this.quantity
        })
        this.$emit('update')
        this.is_loading = false
        this.showModal = false
        await Swal.fire({
          icon: 'success',
          title: 'A New Row Added',
          showConfirmButton: false,
          timer: 1500
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          showConfirmButton: false,
          timer: 1500
        })
      }
    }
  },
  watch: {
    quantity: {
      handler: function (newValue) {
        if (newValue > 100) {
          this.quantity = 100
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.btn-container {
  display: inline-block;
  position: relative;
  height: 1em;
}

.btn-loader-item {
  position: absolute;
  right: 0;
  top: 0
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>