<script>
import {defineComponent} from 'vue'
import OrderActionsCanvas from "@/views/pages/orders/container/details/components/OrderActionsCanvas.vue";

export default defineComponent({
  name: "OrderActions",
  emits: ['update', 'updatedContainers'],
  props: {
    order_number: {
      type: Number,
      required: true,
      default: () => 0
    },
    counterparties: {
      type: Array,
      required: true,
      default: () => []
    },
    container_types: {
      type: Array,
      required: true,
      default: () => [],
    },
    counterparty_list: {
      type: Array,
      required: true,
      default: () => [],
    },
    category_list: {
      type: Array,
      required: true,
      default: () => [],
    },
    actual_costs: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  components: {
    OrderActionsCanvas
  },
})
</script>

<template>
  <button
      class="btn btn-soft-success fw-medium w-100"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
  >See Actions
  </button>

  <OrderActionsCanvas
      :order_number="order_number"
      :counterparties="counterparties"
      :container_types="container_types"
      :counterparty_list="counterparty_list"
      :category_list="category_list"
      :actual_costs="actual_costs"
      @updatedContainers="this.$emit('updatedContainers')"
      @update="this.$emit('update')"
  />
</template>

<style scoped>

</style>