<script>
import OldUI from "@/views/pages/orders/container/details/Index.vue"
import NewUI from "@/views/pages/orders/container/details/NewIndex.vue"

export default {
  name: "SwitchUI",
  components: {
    OldUI,
    NewUI
  },
  data() {
    return {
      ui: null
    }
  },
  mounted() {
    try {
      let user_choice = localStorage.getItem('order_detail_ui')
      if (user_choice && user_choice === 'new') {
        this.ui = 'new'
      } else {
        this.ui = 'old'
      }
    } catch {
      this.ui = 'old'
    }
  }
}
</script>

<template>
  <template v-if="ui">
    <OldUI v-if="ui === 'old'"/>
    <NewUI v-else-if="ui === 'new'"/>
  </template>
</template>

<style scoped>

</style>