<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "AdditionalCost",
  emits: ["update"],
  props: {
    additional_cost: {
      type: String,
      required: true,
      default: '0'
    },
    expense_id: {
      type: Number,
      required: true,
    },
    container_type_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      new_additional_cost: this.additional_cost,
    }
  },
  methods: {
    async updateAdditionalCost() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put(`/container_order/expanse/update_agreed_rate/${this.expense_id}/`, {
          additional_cost: this.new_additional_cost
        })
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'Additional Cost Updated',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    }
  }
})
</script>

<template>
  <input type="number" class="form-control form-control-sm w-75 mx-auto"
         v-model="new_additional_cost"
         placeholder="Additional cost"
         v-on:keyup.enter="updateAdditionalCost"
  >
</template>

<style scoped>

</style>