<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import axios from "axios";
import successAnimation from "@/components/widgets/lupuorrc.json";
import waitingAnimation from "@/components/widgets/xhebrhsj.json";
import Lottie from "@/components/widgets/lottie.vue";
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import Swal from "sweetalert2";

export default {
  name: "CounterpartiesList",
  emits: ['hovered', 'updated', 'showConnectCodeModal'],
  props: {
    index: {
      type: Number,
      required: false,
    },
    counterparty: {
      type: Object,
      required: true
    },
    category: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false
    },
    counterparty_options: {
      type: Array,
      required: true,
      default: () => []
    },
    category_options: {
      type: Array,
      required: true,
      default: () => []
    },
    expenses: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  components: {
    Multiselect,
    lottie: Lottie
  },
  data() {
    return {
      showModal: false,
      counterparty_id: null,
      category_id: null,

      request_status: 'success',

      successAnimation: {animationData: successAnimation},
      waitingAnimation: {animationData: waitingAnimation},

      actual_costs: [],

      counterparty_settings: [],

      counterparties_total: {},

      current_view: 'sales'
    }
  },
  computed: {
    counterparties() {
      return this.counterparty_options.map((option) => {
        return {
          value: option.id,
          label: option.name
        }
      })
    },
    categories() {
      return this.category_options.map((option) => {
        return {
          value: option.id,
          label: option.name
        }
      })
    },
    disableFormSubmit() {
      if (!this.counterparty_id) return true
      if (!this.category_id) return true
      return this.category.id === this.category_id && this.counterparty.counterparty_id === this.counterparty_id;
    },

    expenses_computed() {
      return this.expenses.map(e => {
        let total_expense = e.actual_costs.map(i => parseFloat(i.actual_cost || '0')).reduce((a, b) => a + b, 0)
        return {
          container: e.container || null,
          container_type: e.container_type || '',
          agreed_rate: parseFloat(e.agreed_rate || '0'),
          additional_cost: parseFloat(e.additional_cost || '0'),
          actual_costs: (e.actual_costs || []).map(i => {
            return {
              actual_cost: parseFloat(i.actual_cost || '0'),
              counterparty_id: i.counterparty_id,
              code: i.code || null,
              act: i.act || null,
              category: i.category ? {
                id: i.category.id,
                name: i.category.name,
              } : null,
              counterparty: i.origin_counterparty ? {
                id: i.origin_counterparty.id,
                name: i.origin_counterparty.name,
              } : null
            }
          }),
          total_expense: total_expense,
          total_profit: (parseFloat(e.agreed_rate || '0') + parseFloat(e.additional_cost || '0')) - total_expense,
        }
      })
    }
  },
  methods: {
    mouseover() {
      this.$emit('hovered', this.counterparty)
    },
    mouseleave() {
      if (this.showModal) return;
      this.$emit('hovered', {})
    },
    openModal() {
      this.$emit('hovered', this.counterparty)
      this.request_status = ''
      this.showModal = true
    },

    showConnectCodeModal() {
      this.$emit('showConnectCodeModal', {
        counterparty: {
          id: this.counterparty.id,
          counterparty_id: this.counterparty.counterparty_id,
          name: this.counterparty.name
        },
        categories: this.actual_costs
      })
    },

    handFormSuccess() {
      this.current_view = 'sales'
      this.request_status = ''
    },
    closeModal() {
      this.showModal = false
    },
    updateActualCosts(actual_cost) {
      if (!this.actual_costs.map(i => i.counterparty_id).includes(actual_cost.counterparty_id)) {
        this.actual_costs.push(actual_cost)
      }
    },

    calculateContainerExpense(expense) {
      return expense.actual_costs.map(i => parseFloat(i.actual_cost || '0')).reduce((a, b) => a + b, 0)
    },
    calculateContainerProfit(expense) {
      return (parseFloat(expense.agreed_rate || '0') + parseFloat(expense.additional_cost || '0')) - this.calculateContainerExpense(expense)
    },
    calculateTotalActualCostsForCounterparty(counterparty_id) {
      let total = 0
      this.expenses.forEach(expense => {
        total += expense.actual_costs.filter(actual_cost => actual_cost.counterparty_id === counterparty_id).map(i => parseFloat(i.actual_cost)).reduce((a, b) => a + b, 0)
      })
      return total
    },
    calculateTotalExpense() {
      return this.expenses_computed.map(e => e.total_expense).reduce((a, b) => a + b, 0)
    },
    calculateTotalProfit() {
      return this.expenses_computed.map(e => e.total_profit).reduce((a, b) => a + b, 0)
    },
    calculateTotalAgreedRate() {
      return this.expenses_computed.map(e => e.agreed_rate).reduce((a, b) => a + b, 0)
    },
    calculateTotalAdditionalCost() {
      return this.expenses_computed.map(e => e.additional_cost).reduce((a, b) => a + b, 0)
    },
    async updateCounterpartyAndCategory(counterparty_id, counterparty, category) {
      if (!counterparty || !category) {
        return alert("Counterparty or Category is not selected!")
      }

      await Swal.fire({
        icon: "info",
        title: `Are you sure you want to update?`,
        showCancelButton: true,
        confirmButtonText: "Yes, update",
      }).then(async () => {
        this.request_status = 'loading'
        try {
          await axios.put(`/order/counterparty/update/${counterparty_id}/`, {
            category_id: category.value,
            counterparty_id: counterparty.value
          })
          await Swal.fire({
            title: "Successfully updated",
            icon: 'success'
          }).then(() => {
            window.location.reload()
          })
        } catch {
          this.request_status = 'error'
          await Swal.fire({
            title: "Oops..",
            text: 'Something went wrong while updating the counterparty',
            icon: 'error',
          })
        }
      })
    },
    async deleteCounterpartyAndCategory(counterparty_id, counterparty, category) {

      await Swal.fire({
        icon: "error",
        title: `Are you sure you want to remove ${counterparty.label} / ${category.label} from Order ${this.$route.params.id}`,
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, remove it!",
        confirmButtonColor: "#f06548",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.delete(`/order/counterparty/delete/${counterparty_id}/`)
            await Swal.fire({
              title: "Successfully deleted",
              icon: 'success'
            }).then(() => {
              window.location.reload()
            })
          } catch {
            await Swal.fire({
              title: "Oops..",
              text: 'Something went wrong while deleting the counterparty',
              icon: 'error',
            })
          }
        }
      });
    },

    async exportToExcel() {
      try {
        const workbook = new ExcelJS.Workbook();

        // Create sheets
        const salesSheet = workbook.addWorksheet('Sales');
        const codesSheet = workbook.addWorksheet('Codes');
        const paymentsSheet = workbook.addWorksheet('Payments');

        // Function to add headers and data to a worksheet
        const addDataToSheet = (worksheet, view) => {
          // Add header row
          worksheet.addRow([
            '#',
            'Container',
            'Type',
            ...this.actual_costs.map(cost => cost.category.name),
            'Total Expense'
          ]);

          // Add data rows
          this.expenses_computed.forEach((expense, index) => {
            let row;
            if (view === 'sales') {
              row = [
                index + 1,
                expense.container ? expense.container.name || '-' : '-',
                expense.container_type,
                ...expense.actual_costs.map(actual_cost => actual_cost.actual_cost.toFixed(2)),
                expense.total_expense.toFixed(2)
              ];
            } else if (view === 'codes') {
              row = [
                index + 1,
                expense.container ? expense.container.name || '-' : '-',
                expense.container_type,
                ...expense.actual_costs.map(actual_cost => actual_cost.code ? actual_cost.code.number : ''),
                expense.total_expense.toFixed(2)
              ];
            } else if (view === 'payments') {
              row = [
                index + 1,
                expense.container ? expense.container.name || '-' : '-',
                expense.container_type,
                ...expense.actual_costs.map(actual_cost => actual_cost.act ? actual_cost.act.name : ''),
                expense.total_expense.toFixed(2)
              ];
            }
            worksheet.addRow(row);
          });

          if (this.current_view === 'sales') {
            // Add totals row
            const totalRow = [
              '', '', '',
              ...this.actual_costs.map(cost => this.calculateTotalActualCostsForCounterparty(cost.counterparty_id).toFixed(2)),
              this.calculateTotalExpense().toFixed(2)
            ];
            worksheet.addRow(totalRow);
          }
        };

        // Add data to each sheet
        addDataToSheet(salesSheet, 'sales');
        addDataToSheet(codesSheet, 'codes');
        addDataToSheet(paymentsSheet, 'payments');

        // Generate the Excel file and trigger the download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        try {
          let counterparty_name = this.counterparty.name
              .toLowerCase() // Convert the string to lowercase
              .trim() // Trim whitespace from the beginning and end
              .replace(/[^\w\s-]/g, '') // Remove all non-word characters, except spaces and hyphens
              .replace(/[\s_-]+/g, '-') // Replace spaces, underscores, and hyphens with a single hyphen
              .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
          saveAs(blob, `Order_${this.$route.params.id}_${counterparty_name}.xlsx`);
        } catch {
          saveAs(blob, `excel_file.xlsx`);
        }
      } catch {
        alert("error");
      }
    }
  },
  watch: {
    expenses: {
      handler() {
        try {
          this.actual_costs = []
          this.expenses_computed.forEach(expense => {
            expense.actual_costs.forEach(actual_cost => {
              if (!this.actual_costs.map(i => i.counterparty_id).includes(actual_cost.counterparty_id)) {
                this.actual_costs.push({
                  counterparty_id: actual_cost.counterparty_id,
                  category: actual_cost.category,
                })
              }
              if (!this.counterparty_settings.map(i => i.counterparty_id).includes(actual_cost.counterparty_id)) {
                this.counterparty_settings.push({
                  counterparty_id: actual_cost.counterparty_id,
                  category: {
                    value: actual_cost.category.id,
                    label: actual_cost.category.name,
                  },
                  counterparty: {
                    value: actual_cost.counterparty.id,
                    label: actual_cost.counterparty.name
                  }
                })
              }
            })
          })
        } catch {
          this.showModal = false
        }
      },
      immediate: true
    },
    counterparty: {
      handler(newVal) {
        if (newVal) {
          this.counterparty_id = newVal.counterparty_id
        } else {
          this.counterparty_id = null
          this.showModal = false
        }
      },
      immediate: true,
    },
    category: {
      handler(newVal) {
        if (newVal) {
          this.category_id = newVal.id
        } else {
          this.category_id = null
          this.showModal = false
        }
      },
      immediate: true,
    },
    showModal() {
      if (this.showModal === false) {
        this.$emit('hovered', {})
      }
    }
  }
}
</script>

<template>

  <b-modal v-model="showModal" centered hide-footer hide-header
           hide-header-close size="xl" v-if="counterparty && category">


    <div class="row gy-3">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-12 col-md-4 d-none d-md-block"></div>
          <div class="col-12 col-md-4">
            <div class="d-flex flex-column gap-2 align-items-center w-100">
              <span class="text-muted">
                Counterparty
              </span>
              <h4 class="mb-0">
                {{ counterparty.name }}
              </h4>
            </div>
          </div>
          <div class="col-12 col-md-4 text-end">
            <BButton @click="exportToExcel()" variant="outline-success" class="waves-effect waves-light">
              <i class="mdi mdi-file-excel"></i> Download Excel
            </BButton>
          </div>
        </div>
      </div>

      <div class="col-12 rounded-3">
        <div class="">
          <ul class="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist"
              aria-orientation="horizontal">
            <li class="nav-item" role="presentation">
              <button @click="current_view = 'sales'" class="nav-link" :class="{'active': current_view === 'sales'}"
                      role="tab">
                  <span class="d-block d-sm-none">
                    <i class="mdi mdi-home-variant"></i>
                  </span>
                <span class="d-none d-sm-block">Sales</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="current_view = 'codes'" class="nav-link" :class="{'active': current_view === 'codes'}"
                      role="tab">
                  <span class="d-block d-sm-none">
                    <i class="mdi mdi-home-variant"></i>
                  </span>
                <span class="d-none d-sm-block">Codes</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="current_view = 'payments'" class="nav-link"
                      :class="{'active': current_view === 'payments'}" role="tab">
                  <span class="d-block d-sm-none">
                    <i class="mdi mdi-home-variant"></i>
                  </span>
                <span class="d-none d-sm-block">Payments</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button @click="current_view = 'settings'" class="nav-link"
                      :class="{'active': current_view === 'settings'}"
                      role="tab">
                  <span class="d-block d-sm-none">
                    <i class="mdi mdi-home-variant"></i>
                  </span>
                <span class="d-none d-sm-block">Settings</span>
              </button>
            </li>
          </ul>
        </div>

        <div v-if="current_view !== 'settings'" class="table-responsive mb-0">
          <table class="table table-sm table-nowrap table-bordered mb-0">
            <thead>
            <tr class="align-middle">
              <th class="text-center" scope="col">#</th>
              <th style="min-width: 100px" scope="col" class="text-center py-3">Container</th>
              <th style="min-width: 100px" scope="col" class="text-center py-3">Type</th>
              <template v-for="cost in actual_costs" :key="`max_expense_${cost}`">
                <th style="min-width: 100px" scope="col" class="text-center py-3">
                     <span class="badge badge-soft-success fs-11">
                        {{ cost.category.name }}
                     </span>
                </th>
              </template>
              <th class="text-center">Expense</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(expense, index) in expenses_computed" :key="`expense_${expense.container_type}`">
              <tr class="align-middle">
                <th class="text-center" scope="row">
                  {{ index + 1 }}
                </th>
                <td class="text-center">
                  {{ expense.container ? expense.container.name || '-' : '-' }}
                </td>
                <td class="text-center">
                  {{ expense.container_type }}
                </td>
                <template v-for="actual_cost in expense.actual_costs" :key="`actual_cost_${actual_cost}`">
                  <td class="text-center position-relative">
                    <div v-if="current_view === 'sales'"
                         class="d-flex justify-content-between align-items-center gap-2 flex-wrap">
                      <div>
                        <i v-b-tooltip.hover :title="actual_cost.code.number + ' - ' + actual_cost.code.status"
                           v-if="actual_cost.code"
                           class="mdi mdi-circle"
                           :class="{
                               'text-secondary': actual_cost.code.status === 'used',
                               'text-danger': actual_cost.code.status === 'canceled',
                               'text-success': actual_cost.code.status === 'completed',
                               'text-warning': actual_cost.code.status === 'checking',
                             }"
                        ></i>
                      </div>
                      <div class="text-center">
                        $ {{
                          actual_cost.actual_cost.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </div>
                      <div>
                        <i v-b-tooltip.hover :title="actual_cost.act.name"
                           v-if="actual_cost.act" class="mdi mdi-circle text-primary"></i>
                      </div>
                    </div>
                    <div v-else-if="current_view === 'codes'">
                      <div v-if="actual_cost.code" class="d-flex justify-content-between align-items-center gap-2">
                        <i v-b-tooltip.hover :title="actual_cost.code.number + ' - ' + actual_cost.code.status"
                           class="mdi mdi-circle"
                           :class="{
                               'text-secondary': actual_cost.code.status === 'used',
                               'text-danger': actual_cost.code.status === 'canceled',
                               'text-success': actual_cost.code.status === 'completed',
                               'text-warning': actual_cost.code.status === 'checking',
                             }"
                        ></i>
                        <span>
                          <router-link class="text-dark" @click="showModal = false" :to="{name: 'Codes', query: {
                            number: actual_cost.code.number,
                            forwarder: actual_cost.counterparty.id
                          }}">
                            {{ actual_cost.code.number }}
                          </router-link>
                        </span>
                        <span></span>
                      </div>

                    </div>
                    <div v-else-if="current_view === 'payments'">
                      <div v-if="actual_cost.act" class="d-flex gap-3 px-2 justify-content-center align-items-center">

                        <router-link @click="showModal = false" class="text-dark" :to="{name: 'counterparty_profile_contracts_acts', params: {
                            slug: actual_cost.act.company_slug,
                            contract_slug: actual_cost.act.contract_slug
                          }, query: {
                            act: actual_cost.act.slug
                          }}">
                          {{ actual_cost.act.name }}
                        </router-link>

                        <div>
                          <i v-b-tooltip.hover title="Act" class="mdi mdi-circle text-primary"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                </template>
                <td class="text-center text-danger fw-medium">
                  {{ expense.total_expense.toLocaleString("en-US", {style: "currency", currency: "USD"}) }}
                </td>
              </tr>
            </template>
            <template v-if="current_view === 'sales'">
              <tr class="align-middle">
                <th colspan="3">
                </th>
                <template v-for="actual_cost in actual_costs" :key="`counterparty_total_actual_cost_${actual_cost}`">
                  <th class="text-center py-2">
                    $ {{
                      calculateTotalActualCostsForCounterparty(actual_cost.counterparty_id).toLocaleString(undefined, {
                        minimumFractionDigits: 2, maximumFractionDigits: 2
                      })
                    }}
                  </th>
                </template>
                <th class="text-center py-2">
                  $ {{
                    calculateTotalExpense().toLocaleString(undefined, {
                      minimumFractionDigits: 2, maximumFractionDigits: 2
                    })
                  }}
                </th>
              </tr>
              <tr class="align-middle">
                <th colspan="3" class="text-center">
                  TOTAL
                </th>
                <th :colspan="actual_costs.length" class="text-center py-2">
                  $ {{
                    calculateTotalExpense().toLocaleString(undefined, {
                      minimumFractionDigits: 2, maximumFractionDigits: 2
                    })
                  }}
                </th>
                <th></th>
              </tr>
            </template>
            </tbody>
          </table>
        </div>

        <template v-else>

          <div v-if="request_status === ''" class="col-12">
            <div class="row g-4">
              <template v-for="counterparty in counterparty_settings" :key="`counterparty_${counterparty}`">
                <div class="col-12 col-lg-6 col-xl-4">

                  <div class="d-flex flex-column gap-3">
                    <div>
                      <label class="form-label">Counterparty</label>
                      <Multiselect v-model="counterparty.counterparty" placeholder="Select counterparty"
                                   :options="counterparties"
                                   :searchable="true" :object="true"/>
                    </div>

                    <div>
                      <label class="form-label">Category</label>
                      <Multiselect v-model="counterparty.category" placeholder="Select category" :options="categories"
                                   :searchable="true" :object="true"/>
                    </div>

                    <div class="row g-3">
                      <div class="col-6">
                        <b-button
                            @click="updateCounterpartyAndCategory(counterparty.counterparty_id, counterparty.counterparty, counterparty.category)"
                            :disabled="!counterparty.counterparty || !counterparty.category"
                            variant="success" class="w-100">Update
                        </b-button>
                      </div>
                      <div class="col-6">
                        <b-button
                            @click="deleteCounterpartyAndCategory(counterparty.counterparty_id, counterparty.counterparty, counterparty.category)"
                            :disabled="!counterparty.counterparty || !counterparty.category"
                            variant="outline-danger" class="w-100">Remove
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="request_status === 'loading'" class="col-12 mt-3 text-center">
            <lottie
                colors="primary:#405189,secondary:#02a8b5"
                :options="waitingAnimation"
                :height="100"
                :width="100"
                class="mb-3"
            />
            <h5>
              Please, wait...
            </h5>
          </div>

          <div v-else-if="request_status === 'error'" class="col-12 text-center">
            <i class="mdi mdi-restore-alert display-1 text-danger"></i>
            <h3 class="mb-3">Oops</h3>
            <p class="fs-16 text-muted">
              Something went wrong while saving the changes. <br> Please, try again (
            </p>
            <div>
              <b-button variant="success" @click="request_status = ''">
                Try again
              </b-button>
            </div>
          </div>

          <div v-else-if="request_status === 'success'" class="col-12 mt-3 text-center">
            <lottie
                colors="primary:#405189,secondary:#02a8b5"
                :options="successAnimation"
                :height="100"
                :width="100"
                class="mb-3"
            />
            <h5 class="mb-4">
              Successfully Saved
            </h5>

            <b-button variant="success" @click="handFormSuccess()">Ok</b-button>
          </div>
        </template>

      </div>
    </div>

  </b-modal>

  <li v-if="counterparty"
      @mouseover.stop="mouseover"
      @mouseleave.stop="mouseleave"
      @click.stop="openModal()"
      :class="{ 'active cursor-pointer' : active }"
  >
    <a class="d-flex justify-content-between align-items-center gap-3">
      <div class="d-flex align-items-center gap-2">
        <div class="flex-shrink-0 chat-user-img online align-self-center ms-0">
          <div class="avatar-xxs">
            <div class="avatar-title bg-light rounded-circle text-body fw-medium">
              {{ index ? index + 1 : '#' }}
            </div>
          </div>
        </div>
        <div class="flex-grow-1 overflow-hidden">
          <p class="text-truncate mb-0 fs-6">
            {{ counterparty.name || '-' }}
          </p>
        </div>
      </div>
      <div>
        <b-button
            @click.stop="showConnectCodeModal()"
            v-b-tooltip.hover title="Connect codes" variant="soft-success" size="sm" class="">
          +
        </b-button>
      </div>
    </a>
  </li>
</template>
