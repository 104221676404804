<script>
import {defineComponent} from 'vue'
import ActualCostTab from "@/views/pages/orders/container/details/ActualCostTab.vue";
import PreliminaryTab from "@/views/pages/orders/container/details/PreliminaryTab.vue";

export default defineComponent({
  name: "Tabs",
  emits: ['update'],
  props: {
    order_counterparties: {
      type: Array,
      required: true,
      default: () => []
    },
    container_types: {
      type: Array,
      required: true,
      default: () => []
    },
    counterparty_list: {
      type: Array,
      required: true,
      default: () => []
    },
    category_list: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  components: {
    ActualCostTab,
    PreliminaryTab
  }
})
</script>

<template>
  <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
    <li class="nav-item" role="presentation">
      <a class="nav-link active" data-bs-toggle="tab" href="#actual_cost_tab"
         role="tab" aria-selected="false" tabindex="-1"> ACTUAL COST </a>
    </li>
    <li class="nav-item" role="presentation">
      <a class="nav-link" data-bs-toggle="tab" href="#preliminary_cost_tab"
         role="tab" aria-selected="false" tabindex="-1"> PRELIMINARY COST
      </a>
    </li>
  </ul>

  <div class="tab-content text-muted">
    <ActualCostTab
        @update="this.$emit('update')"
        :order_container_types="container_types"
        :order_counterparties="order_counterparties"
        :counterparty_list="counterparty_list"
        :category_list="category_list"
    />
    <PreliminaryTab
        @update="this.$emit('update')"
        :order_container_types="container_types"
        :order_counterparties="order_counterparties"
        :counterparty_list="counterparty_list"
        :category_list="category_list"
    />
  </div>
</template>

<style scoped>

</style>